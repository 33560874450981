import { CitiesFooter } from '@dreamstack/accentro-contentful'
import {
  ContactFooter,
  GridWithGapsAndPadding,
} from '@dreamstack/feature-components'
import type { FunctionComponent } from 'react'
import tw, { styled } from 'twin.macro'

const FooterLeftHalf = tw.div`flex flex-col w-full md:w-1/2 flex-wrap min-w-0 flex-shrink`
const FooterRightHalf = tw.div`flex flex-col w-full md:w-1/2 flex-wrap min-w-0 flex-shrink`

const CitiesGrid = styled(GridWithGapsAndPadding)(() => [
  tw`min-w-0 pt-px-64 pb-px-128 bg-accentroGray-full text-accentroWhite-full border-accentroWhite-full border-b-2 space-y-px-24 md:(space-y-0 pt-px-80)`,
])

export const CitiesContactFooter: FunctionComponent<React.PropsWithChildren<{}>> = () => {
  return (
    <CitiesGrid>
      <div tw="col-start-1 col-span-12 flex flex-wrap space-y-px-64 md:space-y-0">
        <FooterLeftHalf>
          <CitiesFooter />
        </FooterLeftHalf>
        <FooterRightHalf>
          <ContactFooter />
        </FooterRightHalf>
      </div>
    </CitiesGrid>
  )
}
