import { useAuthModalContext } from '@dreamstack/auth'
import { useTranslation } from '@dreamstack/i18n'
import type { FunctionComponent } from 'react'
import tw from 'twin.macro'
import { SimpleMuiButton } from '../../index'
import { AuthButtonArrow } from './AuthButtonArrow'

const StyledText = tw.span`font-montserrat mb-px-40 ml:mb-px-48`

export const ConfirmationEmailSentSuccessPage: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  const t = useTranslation()
  const { resetModal } = useAuthModalContext()
  return (
    <>
      <StyledText>{t('accentro:ConfirmationEmailSentText')}</StyledText>
      <SimpleMuiButton
        onClick={resetModal}
        color="primary"
        variant="contained"
        type="button"
      >
        {t('accentro:continueOnAccentro')}
        <AuthButtonArrow />
      </SimpleMuiButton>
    </>
  )
}
