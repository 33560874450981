import { signInWithPassword, useAuthModalContext } from '@dreamstack/auth'
import { useTranslation } from '@dreamstack/i18n'
import { SimpleTextLink } from '@dreamstack/simple-components'
import type { FunctionComponent} from 'react';
import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import 'twin.macro'
import tw from 'twin.macro'
import { SimpleMuiButton } from '../../index'
import { useNotificationContext } from '../../notification/NotificationContext'
import { ControlledPassword } from '../forms/ControlledPassword'
import { useAuthYupSchema } from '../validation/yupSchema'
import { AuthEmailDisplay } from './AuthEmailDisplay'

type LoginFormData = {
  password: string
}

const ForgotPasswordLink = tw(SimpleTextLink)``

const AdditionalContainer = tw.div`
  flex
  flex-col
  items-end
  mt-px-8
`

const ContentWrapper = tw.div`space-y-px-16 md:space-y-px-24`
const ActionWrapper = tw.div`flex flex-row flex-wrap gap-px-16 mt-px-40 ml:mt-px-48`
export const LoginWithPassword: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  const { setActiveStep, resolveActions, resetModal, email } =
    useAuthModalContext()
  const t = useTranslation()
  // TODO: Replace with actual confirmation status of email.
  const emailConfirmed = true
  const [errorMessage, setErrorMessage] = useState('')
  const { addNotification } = useNotificationContext()

  const yupSchemaResolver = useAuthYupSchema(['password'])

  const onSubmit = async (data: LoginFormData) => {
    const { password } = data

    const signInResponse = await signInWithPassword({
      email,
      password,
    })
    const success = !!signInResponse?.ok

    if (success) {
      if (!emailConfirmed) {
        setActiveStep('ConfirmationEmailSent')
        return
      }
      resolveActions()
    } else {
      setErrorMessage(t('accentro:wrongPassword'))
    }
  }
  const methods = useForm<LoginFormData>({ resolver: yupSchemaResolver })
  const { control, handleSubmit } = methods

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ContentWrapper>
          <AuthEmailDisplay />
          <div>
            <ControlledPassword
              label={t('accentro:password')}
              name="password"
              autoFocus
              control={control}
              errorMessage={errorMessage}
              onChangeCb={() => setErrorMessage('')}
            />
            <AdditionalContainer>
              <ForgotPasswordLink
                tw="font-semibold"
                onClick={() => {
                  setActiveStep('resetPassword')
                }}
              >
                {t('accentro:forgotPassword')}
              </ForgotPasswordLink>
            </AdditionalContainer>
          </div>
        </ContentWrapper>
        <ActionWrapper tw="flex flex-row flex-wrap gap-px-16 mt-px-40 ml:mt-px-48">
          <SimpleMuiButton variant="contained" type="submit" color="primary">
            {t('accentro:auth.login_anmelden')}
          </SimpleMuiButton>
          <SimpleMuiButton
            variant="outlined"
            type="button"
            onClick={resetModal}
          >
            {t('accentro:cancel')}
          </SimpleMuiButton>
        </ActionWrapper>
      </form>
    </FormProvider>
  )
}
