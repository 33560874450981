import { SimpleNotification } from '@dreamstack/simple-components'
import last from 'lodash/last'
import type { FunctionComponent } from 'react'
import { useNotificationContext } from './NotificationContext'

export const NotificationHub: FunctionComponent<React.PropsWithChildren<{}>> = () => {
  const { notifications } = useNotificationContext()
  const n = last(notifications)
  if (!n) return null
  return <SimpleNotification text={n.text} type={n.type} key={n.createdAt} />
}
