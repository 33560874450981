export const formatPhoneForHref = ({ digits }: { digits?: string }) => {
  if (!digits) return undefined

  // Remove all spaces
  const phoneWithoutSpaces = digits.replace(/\s/g, '')

  // Remove all dashes
  const phoneWithoutDashes = phoneWithoutSpaces.replace(/-/g, '')

  // Remove leading zero
  const phoneWithoutLeadingZero = phoneWithoutDashes.replace(/^0/, '')

  // Add country code
  const phoneWithCountryCode = `tel:+49${phoneWithoutLeadingZero}`

  return phoneWithCountryCode
}
