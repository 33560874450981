import { SimpleCheckbox, SimpleFieldError } from '@dreamstack/simple-components'
import type { Dispatch, FunctionComponent, SetStateAction } from 'react'
import React from 'react'
import { useFormContext } from 'react-hook-form'

export const FormCheckbox: FunctionComponent<
  React.PropsWithChildren<{
    propertyName: string
    labelComponent: React.ReactNode
    isOnDarkBg?: boolean
    onChangeCallback?: Dispatch<SetStateAction<boolean>>
    checked?: boolean
  }>
> = ({
  propertyName,
  labelComponent,
  isOnDarkBg = false,
  onChangeCallback,
  checked,
}) => {
  const formContext = useFormContext()
  const error = formContext.getFieldState(propertyName, formContext.formState)
    .error?.message

  const register = useFormContext().register
  return (
    <div>
      <SimpleCheckbox
        checkBoxProps={{
          className: '',
          name: propertyName,
          id: propertyName,
          onChange: (evt) => {
            formContext.setValue(propertyName, evt.target.checked)
            if (onChangeCallback) {
              onChangeCallback(evt.target.checked)
            }
          },
          checked,
        }}
        isOnDarkBg={isOnDarkBg}
        formControlProps={{
          style: {
            color: isOnDarkBg ? 'white' : '#2b2d36',
          },
          ...register(propertyName),
          name: propertyName,
          label: labelComponent,
          id: propertyName,
        }}
      />
      {error && <SimpleFieldError>{error}</SimpleFieldError>}
    </div>
  )
}
