import type { FunctionComponent } from 'react'
import tw from 'twin.macro'

export const GridWithGapsNoCols = tw.div`
grid
gap-x-px-16
md:gap-x-px-48
lg:gap-x-px-64`

export const GridWithGaps = tw(GridWithGapsNoCols)`
  grid-cols-12
`

export const GridWithGapsAndPadding = tw(GridWithGaps)`
  px-px-24
  md:px-px-48
  lg:(padding-left[218px] padding-right[218px])
`
// If Grid Padding changes, change this one as well to maintain full width blocks
// explanation for 'ml' and 'lg' formular: Since the main-grid layout has a max-w-screen width, taking off the padding alone is not enough, we also have to subtract the
// auto generated margin, therefore we take 100vw and substract the max-w-screen width for the viewport (ml: 1366px, lg: 1920px) and also subtract the padding times 2
// (ml: 2*48px = 96px, lg: 2*218px = 436px)
export const FullGridWidthDiv = tw.div`
-mx-px-24 width[calc(100% + 2 * 24px)] 
md:(-mx-px-48 width[calc(100% + 2 * 48px)])
ml:(margin-left[calc(-1 * ((100vw - 1366px + 96px) / 2))] margin-right[calc(-1 * ((100vw - 1366px + 96px) / 2))] width[100vw])  
lg:(margin-left[calc(-1 * ((100vw - 1920px + 436px) / 2))] margin-right[calc(-1 * ((100vw - 1920px + 436px) / 2))] width[100vw])`

export const MainGridStyled = tw(GridWithGapsAndPadding)`
  w-full
  max-w-screen-ml
  lg:max-w-screen-lg
  mx-auto
`

const MainContainerStyled = tw.div`
  col-span-12
`

export const MainContainer: FunctionComponent<React.PropsWithChildren<{ className?: string }>> = ({
  children,
  className,
}) => {
  return (
    <MainGridStyled className={className}>
      <MainContainerStyled>{children}</MainContainerStyled>
    </MainGridStyled>
  )
}

export const MainGrid = MainGridStyled
