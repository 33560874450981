import type { NotificationType } from '@dreamstack/feature-components'
import type { SnackbarCloseReason } from '@material-ui/core'
import { Snackbar } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import type { FunctionComponent } from 'react'
import React from 'react'

export type SimpleNotificationProps = {
  text: string
  type: NotificationType
  duration?: number
}

export const SimpleNotification: FunctionComponent<SimpleNotificationProps> = ({
  text,
  type,
  duration = 6000,
}) => {
  const [open, setOpen] = React.useState(true)

  const handleClose = (
    event: React.SyntheticEvent<any, Event>,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={open}
      autoHideDuration={duration}
      onClose={handleClose}
    >
      <Alert severity={type} onClose={handleClose}>
        {text}
      </Alert>
    </Snackbar>
  )
}
