import type { IOtherLocaleUrlsContext } from '@dreamstack/i18n'

export const MyAccentroBaseUrls = {
  de: 'mein-accentro',
  en: 'my-accentro',
}

export const myAccentroOtherLocaleUrls: IOtherLocaleUrlsContext = {
  de: { url: `/${MyAccentroBaseUrls.de}` },
  en: { url: `/${MyAccentroBaseUrls.en}` },
}

export const myAccentroDocumentsOtherLocaleUrls: IOtherLocaleUrlsContext = {
  de: { url: `/${MyAccentroBaseUrls.de}/documents` },
  en: { url: `/${MyAccentroBaseUrls.en}/documents` },
}

export const myAccentroProfileOtherLocaleUrls: IOtherLocaleUrlsContext = {
  de: { url: `/${MyAccentroBaseUrls.de}/profile` },
  en: { url: `/${MyAccentroBaseUrls.en}/profile` },
}

export const myAccentroFavoritesOtherLocaleUrls: IOtherLocaleUrlsContext = {
  de: { url: `/${MyAccentroBaseUrls.de}/favorites` },
  en: { url: `/${MyAccentroBaseUrls.en}/favorites` },
}

export const myAccentroMessagesOtherLocaleUrls: IOtherLocaleUrlsContext = {
  de: { url: `/${MyAccentroBaseUrls.de}/messages` },
  en: { url: `/${MyAccentroBaseUrls.en}/messages` },
}
