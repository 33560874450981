import type { FunctionComponent } from 'react';
import React, { forwardRef } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'

export type ISimpleTextLink = {
  href?: string
  bold?: boolean
  big?: boolean
  className?: string
  grey?: boolean
  target?: string
  children?: any
  onClick?: () => void
}

// eslint-disable-next-line react/display-name
const StylableLink = forwardRef<HTMLAnchorElement, ISimpleTextLink>(
  ({ big, bold, grey, children, ...props }, ref) => (
    <a {...props} ref={ref}>
      {children}
    </a>
  )
)

// const AccentroLink = tw(StylableLink)`${({
//   big,
//   bold,
//   grey,
// }: ISimpleTextLink) => {
//   const classNames: string[] = []
//   big ? classNames.push('text-lg') : classNames.push('text-base')
//   bold
//     ? big
//       ? classNames.push('font-semibold')
//       : classNames.push('font-semibold')
//     : classNames.push('text-base')
//   grey ? classNames.push('text-black') : classNames.push('textaccentroAqua')
//   return classNames.join(' ')
// }} hover:underline font-montserrat cursor-pointer`

// TODO: FIXME: typing
const AccentroLink: any = styled(StylableLink)<ISimpleTextLink>(
  ({ big, bold, grey }) => [
    big ? tw`text-lg` : tw`text-base`,
    bold ? (big ? tw`font-semibold` : tw`font-semibold`) : tw`text-base`,
    grey ? tw`text-black` : tw`text-accentroAqua-full`,
    tw`hover:underline font-montserrat cursor-pointer`,
  ]
)

export const SimpleTextLink: FunctionComponent<React.PropsWithChildren<ISimpleTextLink>> =
  // eslint-disable-next-line react/display-name
  React.forwardRef(({ ...props }, ref) => {
    return (
      <AccentroLink ref={ref} {...props} className={props.className || ''}>
        {props.children}
      </AccentroLink>
    )
  })
