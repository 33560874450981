import { createContext, useContext } from 'react'

type IPreviewSecretContext = {
  previewModeSecret: string
}

export const PreviewSecretContext = createContext({} as IPreviewSecretContext)

export const usePreviewSecret = () => {
  const { previewModeSecret } = useContext(PreviewSecretContext)
  return previewModeSecret
}
