import type {
  CreateApolloClientSsrOptions} from '@dreamstack/artemis';
import {
  createApolloClientRawSsr
} from '@dreamstack/artemis'
import { useMemo } from 'react'
import { ApolloOptionsWeb } from './ApolloOptionWeb'

export const createApolloClientSsr = (
  options: CreateApolloClientSsrOptions
) => {
  return createApolloClientRawSsr({
    ...ApolloOptionsWeb,
    ...options,
  })
}

export const useCreateApolloClient = (options: CreateApolloClientSsrOptions) =>
  useMemo(() => createApolloClientSsr(options), [options])
