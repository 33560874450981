export const scrollToTarget = (
  tagselector: string,
  event?: React.MouseEvent
) => {
  if (!tagselector) return
  if (event) event.preventDefault()
  const target = document.querySelector(tagselector) as any
  if (target) {
    const targetTopPosition =
      target.getBoundingClientRect().top +
      scrollY -
      window.innerHeight / 2 +
      target.offsetHeight / 2

    scroll({
      top: targetTopPosition,
      behavior: 'smooth',
    })
  }
}
