import React, { useCallback, useState } from 'react'
import { SuccessDialog } from './SuccessDialog'

// TODO: Extract useDialog from successDialog and confirmationDialog.
export const useSuccessDialog = ({
  title,
  text,
  ctaLabel,
  onClose,
  onCtaClick,
}: {
  title?: string
  text: string
  ctaLabel?: string
  onClose?: CallableFunction
  onCtaClick?: CallableFunction
}) => {
  const [open, setOpen] = useState<boolean>(false)
  const show = useCallback(() => {
    setOpen(true)
  }, [setOpen])
  const close = useCallback(() => {
    onClose && onClose()
    setOpen(false)
  }, [setOpen, onClose])
  return {
    SuccessDialog: (
      <SuccessDialog
        text={text}
        title={title}
        ctaLabel={ctaLabel}
        open={open}
        onHandleClose={close}
        onCtaClick={onCtaClick}
      />
    ),
    show,
    close,
  }
}
