import { useTranslation } from '@dreamstack/i18n'
import { useMemo } from 'react'
import * as yup from 'yup'
import { useYupValidationResolver } from '../index'

export type ContactFormData = {
  clientCity?: string
  clientEmail: string
  clientFirstName: string
  clientLastName: string
  clientPhone: string
  clientSalutation: string
  clientStreet: string
  clientZipCode: string
  clientStreetnumber: string
  subject: string
  country?: string
  text: string
  subscribeNewsletter: boolean
  subscribeHomeOwnerShipReport?: boolean
  consent: boolean
}

export const useContactFormYupObject = () => {
  const t = useTranslation()
  const schema = useMemo(
    () => ({
      clientCity: yup.string(),
      clientEmail: yup
        .string()
        .email(t('accentro:pleaseEnterAValidEmail'))
        .required(t('accentro:pleaseEnterAValidEmail')),
      clientFirstName: yup.string(),
      clientLastName: yup.string(),
      clientPhone: yup.string().required(t('accentro:requiredField')),
      clientSalutation: yup.string(),
      clientStreet: yup.string(),
      clientZipCode: yup.string(),
      clientStreetnumber: yup.string(),
      subject: yup.string(),
      country: yup.string(),
      notes: yup.string(),
      //   propertyId: Maybe<Scalars['Int']>;
      text: yup.string().required(t('accentro:requiredField')),

      consent: yup
        .boolean()
        .oneOf([true], t('accentro:generalForm.requiredConsentMessage')),

      subscribeNewsletter: yup.boolean(),
      subscribeHomeOwnerShipReport: yup.boolean(),
    }),
    [t]
  )
  return schema
}

export const useContactFormSchema = () => {
  const schema = useContactFormYupObject()
  return useYupValidationResolver(yup.object(schema))
}
