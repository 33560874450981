import { useAuthModalContext } from '@dreamstack/auth'
import { useTranslation } from '@dreamstack/i18n'
import type { FunctionComponent } from 'react'
import 'twin.macro'
import { Text_16_24, Text_20_30 } from '../../layout/Texts'

export const AuthEmailDisplay: FunctionComponent<React.PropsWithChildren<{}>> = () => {
  const { email } = useAuthModalContext()
  const t = useTranslation()
  return (
    <div>
      <Text_16_24 tw="font-semibold">
        {t('accentro:auth.login.yourEmailAddress')}
      </Text_16_24>
      <Text_20_30>{email}</Text_20_30>
    </div>
  )
}
