import type { Dispatch, FunctionComponent, SetStateAction } from 'react'
import 'twin.macro'

import { LegalImprintText } from '../../auth/modal/LegalImprintText'
import { FormCheckbox } from '../../index'

export const LegalImprintCheckbox: FunctionComponent<React.PropsWithChildren<{
  legalFirm?: string
  isOnDarkBg?: boolean
  name?: string
  onChangeCallback?: Dispatch<SetStateAction<boolean>>
  checked?: boolean
}>> = ({
  isOnDarkBg = false,
  name = 'agreeToLegalImprint',
  legalFirm,
  onChangeCallback,
  checked,
}) => {
  return (
    <div>
      <FormCheckbox
        onChangeCallback={onChangeCallback}
        checked={checked}
        propertyName={name}
        labelComponent={
          <LegalImprintText legalFirm={legalFirm} isOnDarkBg={isOnDarkBg} />
        }
        isOnDarkBg={isOnDarkBg}
      />
    </div>
  )
}
