import type { FunctionComponent} from 'react';
import { createContext, useContext, useMemo } from 'react'
import type { GoogleAnalytics} from './googleAnalytics';
import { googleAnalytics } from './googleAnalytics'

const GoogleAnalyticsContext = createContext<GoogleAnalytics | null>(null)

export const GoogleAnalyticsContextProvider: FunctionComponent<React.PropsWithChildren<{
  trackingId: string
}>> = ({ trackingId, children }) => {
  const ga = useMemo(() => googleAnalytics(trackingId), [trackingId])

  return (
    <GoogleAnalyticsContext.Provider value={ga}>
      {children}
    </GoogleAnalyticsContext.Provider>
  )
}

export function useGoogleAnalytics() {
  return useContext(GoogleAnalyticsContext)
}
