import { useIsLoggedIn } from '@dreamstack/auth'
import { useLocale } from '@dreamstack/i18n'
import {
  getLocalPageViews,
  RECOMMENDATION_LOCAL_STORAGE_IDENTIFIER,
} from '@dreamstack/recommendation'
import { trpc } from '@dreamstack/trpc/client'
import forEach from 'lodash/forEach'
import type { FunctionComponent} from 'react';
import { useEffect } from 'react'

export const SyncUserEngagements: FunctionComponent<React.PropsWithChildren<{}>> = () => {
  const loggedIn = useIsLoggedIn()
  const locale = useLocale()

  const { mutateAsync: addPropertyEngagement } = trpc.analytics.addPropertyEngagement.useMutation()

  useEffect(() => {
    const lastPagesViewed = getLocalPageViews()
    if (!loggedIn || !lastPagesViewed) return
    if (lastPagesViewed.length > 0) {
      forEach(lastPagesViewed, (p) => {
        addPropertyEngagement({
          engagementType: 'view',
          locale,
          propertyId: p.id,
        })
      })
      localStorage.removeItem(RECOMMENDATION_LOCAL_STORAGE_IDENTIFIER)
    }
  }, [addPropertyEngagement, locale, loggedIn])
  return <></>
}
