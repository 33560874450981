import { ApolloLink, HttpLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { createDefaultVarsLink } from '../defaultVars/DefaultVarsLink'
import type { CreateApolloClientOptions } from './CreateApolloClientOptions'
import { createErrorLink } from './ErrorLink'
import { createRetryLink } from './RetryLink'

export const createMainLink = (options: CreateApolloClientOptions) => {
  const { getAuthHeaders, graphqlEndpointUrl } = options

  const defaultVarsLink = createDefaultVarsLink(options)

  const httpAuthLink = setContext(async (graphqlRequest, { headers }) => {
    let newHeaders = { ...headers }
    if (getAuthHeaders) {
      newHeaders = await getAuthHeaders(options, newHeaders, graphqlRequest)
    }
    return { headers: newHeaders }
  })

  if (!graphqlEndpointUrl) {
    throw new Error(`No "graphqlEndpointUrl" defined`)
  }

  const httpLink = new HttpLink({
    uri: graphqlEndpointUrl,
  })

  const retryLink = createRetryLink()
  const errorLink = createErrorLink()

  const mainLink = ApolloLink.from([
    retryLink,
    errorLink,
    defaultVarsLink,
    httpAuthLink,
    httpLink,
  ])
  return mainLink
}
