import { ContentfulRichText } from '@dreamstack/accentro-contentful'
import { useLocale } from '@dreamstack/i18n'
import { trpc } from '@dreamstack/trpc/client'
import type { FunctionComponent } from 'react'
import 'twin.macro'

export const CitiesFooter: FunctionComponent<
  React.PropsWithChildren<{}>
> = () => {
  const locale = useLocale()
    const { data: footer } = trpc.cms.getFooter.useQuery({ locale }, {
        trpc: { ssr: true },
        staleTime: Infinity,
    })

  return (
    <div>
      <div tw="flex flex-col flex-wrap min-w-0">
        <ContentfulRichText richText={footer?.richtext} forceWhiteFont />
      </div>
    </div>
  )
}
