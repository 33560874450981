import { useMemo } from 'react'
import { useSessionTypedWithLoading } from './useSession'

export const useUserIdWithLoading = () => {
  const session = useSessionTypedWithLoading()
  const loading = useMemo(() => {
    return session?.status === 'loading' || session?.status === undefined
  }, [session?.status])
  const userId = session?.id
  return {
    userId,
    loading,
  }
}
