import { createLogger, format } from 'winston'
import { DATADOG_API_KEY, DATADOG_SERVICE_NAME } from './env.config'
import { WaitForRequestsSettledHttpTransport } from './WaitForRequestsSettledHttpTransport'

export const httpTransport = new WaitForRequestsSettledHttpTransport({
  host: 'http-intake.logs.datadoghq.eu',
  path: `/v1/input/${DATADOG_API_KEY}?ddsource=nodejs&service=${DATADOG_SERVICE_NAME}`,
  ssl: true,
})

export const logger = createLogger({
  level: 'info',
  exitOnError: false,
  format: format.json(),
  transports: [
    httpTransport,
    // new transports.Console(),
  ],
})
