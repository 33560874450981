import { signInWithEmail, useAuthModalContext } from '@dreamstack/auth'
import { useTranslation } from '@dreamstack/i18n'
import type { FunctionComponent } from 'react'
import tw from 'twin.macro'
import {
  SimpleMuiButton,
  Text_20_30,
  useNotificationContext,
} from '../../index'

const ContentLayout = tw.div``

export const ConfirmationEmailSent: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  const t = useTranslation()
  const { email } = useAuthModalContext()
  const { addNotification } = useNotificationContext()
  const sendAgain = async () => {
    const signInResponse = await signInWithEmail({
      email,
    })
    const success = !!signInResponse?.ok
    success
      ? addNotification('success', t('accentro:auth.login.success'))
      : addNotification('error', t('accentro:auth.login.error'))
  }
  return (
    <ContentLayout>
      <div tw="flex flex-row flex-wrap gap-px-16">
        <Text_20_30>{t('accentro:noEmailreceived')}</Text_20_30>
        <SimpleMuiButton
          onClick={sendAgain}
          variant="text"
          color="primary"
          type="button"
        >
          <Text_20_30>{t('accentro:sendAgain')}</Text_20_30>
        </SimpleMuiButton>
      </div>
    </ContentLayout>
  )
}
