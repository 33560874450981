import { useIsPreview } from '@dreamstack/artemis'
import { useRouterLocalized } from '@dreamstack/i18n'
import type { FunctionComponent } from 'react'
import 'twin.macro'

export const ExitPreview: FunctionComponent<React.PropsWithChildren<{}>> = () => {
  const isPreview = useIsPreview()
  const { asPathLocalized } = useRouterLocalized()
  return (
    <>
      {isPreview && (
        <a
          href={`/api/preview-exit?url=${asPathLocalized}`}
          tw="w-full fixed text-center p-1 z-50 opacity-80 text-white text-xl top-0 rounded-b bg-gradient-to-r from-red-400 via-blue-400 to-green-400 "
        >
          EXIT PREVIEW
        </a>
      )}
    </>
  )
}
