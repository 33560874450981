import type {
  FunctionComponent} from 'react';
import React, {
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react'

export type Notification = {
  type: NotificationType
  text: string
  createdAt: string
}

export type NotificationType = 'info' | 'error' | 'success' | 'warning'

const useAppContextState = () => {
  const [notifications, setNotifications] = useState<Notification[]>([])

  const addNotification = useCallback(
    (type: NotificationType, text: string) => {
      setNotifications((oldState) => [
        ...oldState,
        { type, text, createdAt: new Date().toISOString() },
      ])
    },
    [setNotifications]
  )

  return {
    notifications,
    setNotifications,
    addNotification,
  }
}

type INotificationContext = ReturnType<typeof useAppContextState>

export const NotificationContext = createContext({} as INotificationContext)

export const NotificationContextProvider: FunctionComponent<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const context: INotificationContext = useAppContextState()
  return (
    <NotificationContext.Provider value={context}>
      {children}
    </NotificationContext.Provider>
  )
}

export function useNotificationContext() {
  return useContext(NotificationContext)
}
