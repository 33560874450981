import type {
  ThemeOptions} from '@material-ui/core/styles';
import {
  createTheme,
  ThemeProvider,
} from '@material-ui/core/styles'
import type { FunctionComponent } from 'react'
import React from 'react'
import TailwindConfig from '../../../../tailwind.config'

export const MuiTheme: FunctionComponent<React.PropsWithChildren<unknown>> = ({ children }) => {
  const extendedTwTheme = TailwindConfig.theme.extend
  const theme = createTheme({
    palette: {
      primary: {
        main: extendedTwTheme.colors.accentroAqua.full,
      },
      secondary: {
        main: extendedTwTheme.colors.accentroGray.full,
      },
      grey: {
        main: extendedTwTheme.colors.accentroGray.full,
      },
      error: {
        light: extendedTwTheme.colors.accentroError[50],
        main: extendedTwTheme.colors.accentroError.full,
        dark: extendedTwTheme.colors.accentroDarkError.full,
      },
    },

    typography: {
      fontFamily: 'Montserrat',
      button: {
        textTransform: 'none',
      },
    },
    spacing: [0, 4, 8, 12, 16, 20, 24, 32, 40, 48, 64, 80],
    shape: { borderRadius: 0 },
    breakpoints: {
      values: {
        xs: 0,
        sm: 0,
        md: parseInt(TailwindConfig.theme.screens.md.replace('px', '')),
        lg: parseInt(TailwindConfig.theme.screens.lg.replace('px', '')),
        xl: parseInt(TailwindConfig.theme.screens.lg.replace('px', '')),
      },
    },
    props: {
      MuiButton: {
        disableElevation: true,
      },
    },
  } as ThemeOptions)

  theme.typography = {
    ...theme.typography,
    fontSize: parseInt(extendedTwTheme.fontSize['px-20'].replace('px', '')),
    [theme.breakpoints.up('md')]: {
      fontSize: extendedTwTheme.fontSize['px-24'].replace('px', ''),
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: extendedTwTheme.fontSize['px-32'].replace('px', ''),
    },
  }

  theme.overrides = {
    MuiFilledInput: {},
    MuiInputBase: {
      // input: {
      //   fontSize: parseInt(extendedTwTheme.fontSize['px-24'].replace('px', '')),
      // },
      //this needs to be overriden for simple select standard variant. If this is problematic consider bootstrapping inputbase
      //for simple select or rewrite another variant.
      formControl: {
        marginTop: '0 !important',
      },
    },
  }
  React.useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side')
    jssStyles?.parentElement?.removeChild(jssStyles)
  }, [])
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}
