// import '../../../libs/theme/src/lib/tailwind.css'
import '@dreamstack/datadog/init' // Initialize datadog with side-effects
import { ExitPreview } from '@dreamstack/feature-components'
import { SpeedInsights } from '@vercel/speed-insights/react'
import 'keen-slider/keen-slider.min.css'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import { MainProvider } from '../components/provider/MainProvider'
import '../public/fonts/fontfaces.css'

// React needs to be imported here... Things break a lot if we dont
import { trpc } from '@dreamstack/trpc/client'
import type { AppContextType } from 'next/dist/shared/lib/utils'
import React from 'react'
const preventReactImportRemovalOnSave = React

function CustomApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <title>DreamStack</title>
      </Head>
      <MainProvider pageProps={pageProps}>
        <ExitPreview />
        <Component {...pageProps} />
        <SpeedInsights />
      </MainProvider>
    </>
  )
}

CustomApp.getInitialProps = async (appContext: AppContextType) => {
  const context = appContext.ctx as any
  if (appContext.router.isPreview) {
    context.HACKY_IS_PREVIEW = true
  }
  return {}
}

export default trpc.withTRPC(CustomApp)
