import { createGlobalStyle } from 'styled-components'
import tw, { GlobalStyles as BaseStyles } from 'twin.macro'

const CustomStyles = createGlobalStyle`
  html, body {
    ${tw`min-h-full`}
  }
  body {
    ${tw`antialiased`}
    font-family: 'Montserrat', sans-serif;
    
    ${tw`
      bg-accentroWhite-full
      text-accentroGray-full
      
      overflow-x-hidden
    `}
  }

  `

//from https://github.com/styled-components/styled-components/issues/1593
//from https://github.com/dothq/browser/commit/1964d4a1ac9eb853d8547e2df77dfa23802e6a0b
if (typeof document !== 'undefined') {
  const fontStyle = document.createElement('style')

  fontStyle.textContent = `
  @font-face {
    font-family: 'Montserrat';
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    src: url('/fonts/Montserrat-Regular.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Montserrat';
    font-weight: 600;
    font-style: normal;
    font-display: swap;
    src: url('/fonts/Montserrat-SemiBold.ttf') format('truetype');
  }
`
  document.head.appendChild(fontStyle)
}
export const GlobalStyles = () => (
  <>
    <BaseStyles />
    <CustomStyles />
  </>
)
