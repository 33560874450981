import type { ButtonProps, Theme } from '@material-ui/core'
import { Button, withStyles } from '@material-ui/core'
import type { FunctionComponent } from 'react'
import React from 'react'
import 'twin.macro'
import { ScreenBreakpoints } from '../config/ScreenBreakpoints'
import { DotAnimationLoader } from './DotAnimationLoader'

interface SimpleMuiButtonProps extends ButtonProps {
  loading?: boolean
  loadingText?: string
}

type Style = Parameters<typeof withStyles>[0]

const createStyle = (theme: Theme, variant: 'regular' | 'warn') => {
  const style: Style = {
    root: {
      fontWeight: 600,
      paddingRight: 24,
      paddingLeft: 24,
      paddingTop: 15,
      paddingBottom: 15,
      fontSize: 16,
      '&:disabled': {
        backgroundColor: 'rgba(43,45,54,0.4)',
        color: 'white',
      },
      [theme.breakpoints.up(ScreenBreakpoints.breakpointMl)]: {
        fontSize: 20,
        paddingRight: 24,
        paddingLeft: 24,
        paddingTop: 20,
        paddingBottom: 20,
      },
    },
    sizeSmall: {
      padding: '12px 16px',
      fontSize: 16,
    },
    startIcon: {
      marginRight: 24,
    },
    outlined: {
      borderWidth: '0px !important',
      borderStyle: 'solid !important',
      outline: '2px solid !important',
      outlineOffset: '-2px !important',
    },
    containedPrimary:
      variant === 'warn'
        ? {
            color: theme.palette.error.contrastText,
            borderColor: theme.palette.error.main,
            backgroundColor: theme.palette.error.main,
            ['&:hover']: {
              backgroundColor: theme.palette.error.dark,
              borderColor: theme.palette.error.dark,
            },
          }
        : {},
    outlinedPrimary: {
      ['&:hover']: variant === 'warn' && {
        backgroundColor: theme.palette.error.light,
        borderColor: theme.palette.error.main,
        outlineColor: theme.palette.error.main,
      },
      color:
        variant === 'warn'
          ? theme.palette.error.main
          : theme.palette.primary.main,
      borderColor:
        variant === 'warn'
          ? theme.palette.error.main
          : theme.palette.primary.main,
    },
    outlinedSecondary: {
      borderColor: theme.palette.secondary.main,
      outlineColor: theme.palette.secondary.main,
    },
    text: { padding: 0 },
    endIcon: {
      marginLeft: 16,
      [theme.breakpoints.up(ScreenBreakpoints.breakpointMd)]: {
        marginLeft: 20,
      },
    },
    label: {},
  }
  return style
}

const StyledMuiButton = withStyles((theme) => createStyle(theme, 'regular'))(
  Button
)

const StyledMuiWarnButton = withStyles((theme) => createStyle(theme, 'warn'))(
  Button
)

/**
 * @deprecated Please use `import { Button } from '@dreamstack/feature-components'`
 */
export const SimpleMuiButton: FunctionComponent<React.PropsWithChildren<SimpleMuiButtonProps>> =
  React.forwardRef(({ children, loading, loadingText, ...props }, ref) => {
    const disableRipple = props.variant === 'text'
    return (
      <StyledMuiButton
        ref={ref}
        tw="focus:outline-none"
        variant="outlined"
        color="secondary"
        disableRipple={disableRipple}
        {...props}
      >
        {!!loading ? (
          <DotAnimationLoader>
            {!!loadingText ? loadingText : children}
          </DotAnimationLoader>
        ) : (
          children
        )}
      </StyledMuiButton>
    )
  })
SimpleMuiButton.displayName = 'SimpleMUIButton'

/**
 * @deprecated Please use `import { Button } from '@dreamstack/feature-components'`
 */
export const SimpleMuiWarnButton: FunctionComponent<React.PropsWithChildren<SimpleMuiButtonProps>> =
  React.forwardRef(({ children, loading, loadingText, ...props }, ref) => {
    const disableRipple = props.variant === 'text'
    return (
      <StyledMuiWarnButton
        ref={ref}
        tw="focus:outline-none"
        variant="outlined"
        color="primary"
        disableRipple={disableRipple}
        {...props}
      >
        {!!loading ? (
          <DotAnimationLoader>
            {!!loadingText ? loadingText : children}
          </DotAnimationLoader>
        ) : (
          children
        )}
      </StyledMuiWarnButton>
    )
  })
SimpleMuiWarnButton.displayName = 'SimpleMuiWarnButton'
