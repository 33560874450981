import { useIsLoggedIn } from '@dreamstack/auth'
import { useTranslation } from '@dreamstack/i18n'
import pick from 'lodash/pick'
import { useMemo } from 'react'
import * as yup from 'yup'
import { useContactFormYupObject } from '../../contactForm/useContactFormSchema'
import { useYupValidationResolver } from '../../hooks/useYupValidationResolver'

type contactSchemaKeys = keyof ReturnType<typeof useContactFormYupObject>
type authSchemakeys = keyof ReturnType<typeof useAuthYupSchemaObject>
type newsletterAndMagazineSchemakeys = keyof ReturnType<
  typeof useNewsletterAndMagazineYupObject
>

const useAuthYupSchemaObject = () => {
  const t = useTranslation()
  const schema = useMemo(
    () => ({
      email: yup
        .string()
        .email(t('accentro:pleaseEnterAValidEmail'))
        .required(t('accentro:pleaseEnterAValidEmail')),
      password: yup.string().required(t('accentro:pleaseEnterAPassword')),
      keepMeLoggedIn: yup.boolean().required(),
      newPassword: yup
        .string()
        .matches(
          /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/,
          t('accentro:passwordNotStrongEnough')
        )
        .required(t('accentro:pleaseEnterAPassword')),
      confirmPassword: yup
        .string()
        .oneOf(
          [yup.ref('newPassword'), null],
          t('accentro:passwordsMustMatch')
        ),
      agreeToLegalImprint: yup
        .boolean()
        .oneOf([true], t('accentro:legalImprintRequired')),
    }),
    [t]
  )
  return schema
}

const useNewsletterAndMagazineYupObject = () => {
  const t = useTranslation()
  const schema = useMemo(
    () => ({
      salutation: yup.string(),
      foreName: yup.string(),
      surName: yup.string(),
      street: yup.string().required(t('accentro:pleaseEnterAStreet')),
      houseNr: yup.string().required(t('accentro:pleaseEnterAHouseNr')),
      postCode: yup.string().required(t('accentro:pleaseEnterAPostCode')),
      city: yup.string().required(t('accentro:pleaseEnterACity')),
      email: yup
        .string()
        .email(t('accentro:pleaseEnterAValidEmail'))
        .required(t('accentro:pleaseEnterAValidEmail')),
      lang: yup.string().oneOf(['en', 'de']),
      agreeToLegalImprint: yup
        .boolean()
        .oneOf([true], t('accentro:legalImprintRequired')),
    }),
    [t]
  )
  return schema
}

const useComingSoonYupObject = () => {
  const t = useTranslation()
  const schema = useMemo(
    () => ({
      clientFirstName: yup
        .string()
        .required(t('accentro:pleaseEnterAForeName')),
      clientLastName: yup.string().required(t('accentro:pleaseEnterASurName')),
      clientEmail: yup
        .string()
        .email(t('accentro:pleaseEnterAValidEmail'))
        .required(t('accentro:pleaseEnterAValidEmail')),
      notes: yup.string(),
      consent: yup.boolean().oneOf([true], t('accentro:legalImprintRequired')),
      subscribeNewsletter: yup.boolean(),
    }),
    [t]
  )
  return schema
}

export const useYupResolver = (
  schema: any,
  fields: authSchemakeys[] | newsletterAndMagazineSchemakeys[] | string[]
) => {
  const schemaByKeys = pick(schema, fields)
  const resolver = useYupValidationResolver(yup.object(schemaByKeys))
  return resolver
}

export const useAuthYupSchema = (fields: authSchemakeys[]) => {
  const schema = useAuthYupSchemaObject()
  return useYupResolver(schema, fields)
}

export const useContactYupSchema = (fields: contactSchemaKeys[]) => {
  const schema = useContactFormYupObject()
  return useYupResolver(schema, fields)
}

export const useNewsletterAndMagazineYupSchema = (
  fields: newsletterAndMagazineSchemakeys[]
) => {
  const schema = useNewsletterAndMagazineYupObject()
  return useYupResolver(schema, fields)
}

export const useConsentYupSchema = () => {
  const t = useTranslation()
  const schema = useMemo(
    () => ({
      consent: yup.boolean().oneOf([true], t('accentro:legalImprintRequired')),
    }),
    [t]
  )
  return useYupResolver(schema, ['consent'])
}

export const useComingSoonYupSchema = () => {
  const loggedIn = useIsLoggedIn()
  const fields = useMemo(() => {
    const commonYupFields = ['consent', 'subscribeNewsletter', 'notes']
    return loggedIn
      ? commonYupFields
      : [
          ...commonYupFields,
          'clientEmail',
          'clientFirstName',
          'clientLastName',
          'consent',
          'subscribeNewsletter',
          'notes',
        ]
  }, [loggedIn])
  const schema = useComingSoonYupObject()
  return useYupResolver(schema, fields)
}
