// export const NewsletterType = {
//   Newsletter: 'newsletter',
//   ComingSoonProject: 'comingSoonProject',
// }

export enum NewsletterType {
  Newsletter = 'newsletter',
}

export type NewsletterJWTData = {
  email: string
  wantsNewsletter: boolean
  projectId?: number
  type: string
}
