import type { Theme } from '@material-ui/core'
import TailwindConfig from '../../../../tailwind.config'

export const LayoutProps = (theme: Theme) => ({
  '& .MuiFilledInput-root': {
    border: '2px solid transparent',
    '&.Mui-error': {
      borderColor: TailwindConfig.theme.extend.colors.accentroError['full'],
    },
  },
  '& .MuiFilledInput-input': {
    padding: '27px 16px 10px',
    fontSize: '20px',
    [theme.breakpoints.up('lg')]: {
      padding: '27px 32px 10px',
      fontSize: '24px',
    },
  },
  '& .MuiFilledInput-multiline': {
    padding: 0,
    lineHeight: '24px',
  },
  '& .MuiInputLabel-filled': {
    transform: 'translate(20px, 26px) scale(1)',
    [theme.breakpoints.up('lg')]: {
      transform: 'translate(32px, 28px) scale(1)',
    },
  },
  '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
    transform: 'translate(18px, 10px) scale(.75)',
    [theme.breakpoints.up('lg')]: {
      transform: 'translate(32px, 10px) scale(.75)',
    },
  },
})
