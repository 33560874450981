// const preview = process.env.NEXT_PUBLIC_SHOW_PREVIEW_CONTENT === 'true'

interface GetDefaultQueryVariablesOptions {
  locale: string
  preview: boolean
}

export const getDefaultQueryVariables = ({
  locale,
  preview,
}: GetDefaultQueryVariablesOptions) => {
  return {
    locale,
    preview,
  }
}
