import { signIn } from 'next-auth/react'
import type { SignInWithEmailOptions } from './signInWithEmail'

export interface SignInWithPasswordOptions extends SignInWithEmailOptions {
  password: string
}
export const signInWithPassword = async ({
  email,
  password,
}: SignInWithPasswordOptions) => {
  return signIn('credentials', {
    email,
    password,
    redirect: false,
  })
}
