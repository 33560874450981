export const PROJECT_ID = process.env.NEXT_PUBLIC_PROJECT_ID as
  | 'accentro-web'
  | 'accentro-investors'
  | 'accentro-international'

export const SITE_NAME = 'Accentro'
export const BASE_URL = process.env.NEXT_PUBLIC_NEXT_BASE_URL!

export const SHOW_NAV_LOGIN = PROJECT_ID === 'accentro-web'
// export const SHOW_NAV_LOGIN = false

// export const SHOW_NAV_SEARCH = PROJECT_ID === 'accentro-investors'
export const SHOW_NAV_SEARCH = false

export const IS_ACCENTRO_WEB = PROJECT_ID === 'accentro-web'
export const IS_ACCENTRO_INVESTORS = PROJECT_ID === 'accentro-investors'
export const IS_ACCENTRO_INTERNATIONAL = PROJECT_ID === 'accentro-international'

export const ENABLE_SEO_FOLLOW_INDEX =
  process.env.NEXT_PUBLIC_ENABLE_SEO_FOLLOW_INDEX === 'true'
