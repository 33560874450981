import filter from 'lodash/filter'
import { useRouter } from 'next/router'

export const useRouterLocalized = () => {
  const { asPath, locale, locales, ...props } = useRouter()

  const localeUrlPart = locale === 'de' ? '' : locale

  const otherLocales = filter(locales, (l) => l !== locale)

  return {
    ...props,
    asPathLocalized: `${localeUrlPart}${asPath}`,
    localeUrlPart,
    asPath,
    locales,
    otherLocales,
    locale,
  }
}
