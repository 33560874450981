import type { Operation } from '@apollo/client'
import type {
  CreateApolloClientOptions,
  GetApolloAuthHeaders,
} from '@dreamstack/artemis'
import { logger } from '@dreamstack/logger/src/logger'
import includes from 'lodash/includes'
import { possibleTypes } from './possibleTypes'

const contentfulFastLaneOperationNames = [
  'GetNavBarEntries',
  'GetNewsArticleBySlug',
  'GetNewsArticlePreviews',
  'GetNewsArticleSlugs',
  'GetOtherNewsArticlePreviewsOfType',
  'GetProjectImageMapById',
  'GetProjectLinkSectionById',
  'GetProjectPageById',
  'GetAllStaticFilters',
  'GetBlockDataById',
  'GetAccordionById',
  'GetAccordionEntryById',
  'GetBarChartWithBackgroundById',
  'GetBlockAnalystRatingById',
  'GetBlockBigTitleNavigationById',
  'GetBlockHighlightRealEstateById',
  'GetBlockImageGalleryById',
  'GetBlockLinkSectionById',
  'GetBlockPartnerGridById',
  'GetBlockSliderById',
  'GetBlockSliderEntryById',
  'GetBlockSpeechBubbleById',
  'GetBlockStockPriceById',
  'GetBlockSubNavigationById',
  'GetBlockTextCloudById',
  'GetBlockThreeColumnById',
  'GetBlockVideoById',
  'GetCustomBlockById',
  'GetFullBlockCallToActionButtonById',
  'GetFullBlockFullsizeImageById',
  'GetFullBlockRibbonById',
  'GetFullBlockRichTextById',
  'GetFullBlockTableById',
  'GetLatestDownloadById',
  'GetNavigationEntryById',
  'GetOneColumnLayoutById',
  'GetPersonAccordionById',
  'GetPersonAccordionEntryById',
  'GetTwoColumnLayoutById',
  'GetStaticPagesBySlug',
  'GetStaticPageSlugById',
  'GetStaticPageSlugs',
  'LatestDownloadByType',
  'GetAllNewsArticleByType',
  'GetTopNewsArticlePreviews',
  'GetNewsArticlesFullText',
  'GetLexiconArticlePreviews',
  'GetTopLexiconArticlePreviews',
  'GetNewsArticlePreviewsOfLocation',
  'GetNewsArticlePreviewsOfThreeNewsType',
  'GetBlockHiddenWithConsentWithoutChildrenById',
  'GetBlockHiddenWithConsentById',
  'GetFooter',
]

const isContentfulFastLaneOperation = ({
  operationName,
}: Partial<Operation>) => {
  const isFastLane = includes(contentfulFastLaneOperationNames, operationName)
  return isFastLane
}

const getAuthHeaders: GetApolloAuthHeaders = async (
  options,
  headers,
  operation
) => {
  const { includeAdminToken, getAuthToken, previewModeSecret } = options

  const isContentfulFastLane = isContentfulFastLaneOperation(operation)

  let token: string | undefined
  if (isContentfulFastLane) {
    if (previewModeSecret) {
      if (previewModeSecret !== process.env.PREVIEW_MODE_SECRET) {
        throw new Error('Invalid previewModeSecret')
      }
      token = process.env.CONTENTFUL_API_TOKEN_PREVIEW
    } else {
      token = process.env.CONTENTFUL_API_TOKEN_PUBLIC
    }
  } else if (getAuthToken) {
    token = await getAuthToken()
  }

  if (token) {
    headers['Authorization'] = `Bearer ${token}`
  }

  if (includeAdminToken) {
    headers['x-hasura-admin-secret'] = process.env.HASURA_ADMIN_SECRET!
    headers['x-api-key'] = process.env.PROPSTACK_API_KEY!
  }

  if (previewModeSecret) {
    headers['x-preview-mode-secret'] = previewModeSecret
  }

  return headers
}

export const ApolloOptionsWeb: Partial<CreateApolloClientOptions> = {
  possibleTypes,
  graphqlEndpointUrl: (operation) => {
    const isContentfulFastLane = isContentfulFastLaneOperation(operation)

    const endpointUrl = isContentfulFastLane
      ? process.env.CONTENTFUL_ENDPOINT_URL
      : process.env.NEXT_PUBLIC_GRAPHQL_API

    if (!endpointUrl) {
      const error = isContentfulFastLane
        ? `'endpointUrl' is undefined! GraphQL Operation '${operation.operationName}' will fail. Most likely you need to make the call in getStaticProps().`
        : `'endpointUrl' is undefined!`
      logger.error(new Error(error))
      return 'UNDEFINED_ENDPOINT__CHECK_THE_LOGS'
    }

    return endpointUrl
  },
  getAuthHeaders,
}
