import type { ApolloClient } from '@apollo/client'
import { createApolloClient } from './createApolloClient'
import type { CreateApolloClientOptions } from './CreateApolloClientOptions'

export interface CreateApolloClientSsrOptions
  extends CreateApolloClientOptions {
  initialState?: any
}

let apolloClientCached: ApolloClient<any>
export const createApolloClientRawSsr = (
  options: CreateApolloClientSsrOptions
) => {
  const { initialState } = options
  const isServer = typeof window === 'undefined'
  const apolloClient = apolloClientCached ?? createApolloClient(options)

  // Restore Initial State (from getStaticProps)
  if (initialState) {
    const existingCache = apolloClient.extract()
    apolloClient.cache.restore({ ...existingCache, ...initialState })
  }

  // Only create apolloClient once on client
  // TODO: find out why?
  if (!isServer) {
    apolloClientCached = apolloClient
  }

  return apolloClient
}
