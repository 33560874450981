import { useModalState } from '@dreamstack/auth'
import { SimpleModal } from '@dreamstack/simple-components'
import type { FunctionComponent } from 'react'
import { useAuthModalActiveComponent } from './modal/useAuthModalActiveComponent'

export const AuthModal: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  const { open, handleClose } = useModalState()
  const authModalActiveComponent = useAuthModalActiveComponent()
  return (
    <SimpleModal
      handleClose={handleClose}
      goBackTo={authModalActiveComponent?.goBackTo}
      title={authModalActiveComponent?.title ?? ''}
      open={open}
      intro={authModalActiveComponent?.intro}
    >
      {authModalActiveComponent?.component}
    </SimpleModal>
  )
}
