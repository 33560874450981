import { signInWithEmail, useAuthModalContext } from '@dreamstack/auth'
import { useTranslation } from '@dreamstack/i18n'
import { SimpleFieldError } from '@dreamstack/simple-components'
import type { FunctionComponent } from 'react'
import { useCallback, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import 'twin.macro'
import tw from 'twin.macro'
import { SimpleMuiButton } from '../../index'
import { AuthEmailDisplay } from './AuthEmailDisplay'

export type LoginFormData = {
  email: string
}

const StyledForm = tw.form`space-y-px-40 md:space-y-px-48`

export const Login: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  const { setActiveStep, email } = useAuthModalContext()
  const t = useTranslation()
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)

  const onSubmit = useCallback(async () => {
    setLoading(true)
    const signInResponse = await signInWithEmail({
      email,
    })
    const success = !!signInResponse?.ok
    setLoading(false)
    success
      ? setActiveStep('ConfirmationEmailSent')
      : setErrorMessage(t('accentro:auth.login.error'))
  }, [email, setActiveStep, t])

  const methods = useForm<LoginFormData>()
  const { handleSubmit } = methods

  return (
    <FormProvider {...methods}>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <AuthEmailDisplay />
        {!!errorMessage && <SimpleFieldError>{errorMessage}</SimpleFieldError>}
        <div tw="flex flex-row flex-wrap gap-px-16">
          <SimpleMuiButton
            variant="contained"
            type="submit"
            color="primary"
            loading={loading}
            disabled={loading}
          >
            {t('accentro:auth.requestLoginLink')}
          </SimpleMuiButton>
          <SimpleMuiButton
            variant="outlined"
            type="button"
            onClick={() => setActiveStep('loginWithPassword')}
          >
            {t('accentro:auth.enterPassword')}
          </SimpleMuiButton>
        </div>
      </StyledForm>
    </FormProvider>
  )
}
