import { useAuthModalContext, useIsLoggedIn } from '@dreamstack/auth'
import { useRouter } from 'next/router'
import type { FunctionComponent } from 'react'
import { useEffect } from 'react'

export type QueryParamAction = 'login'

export const QueryParamActionHandler: FunctionComponent<React.PropsWithChildren<{}>> = () => {
  const loggedIn = useIsLoggedIn()
  const { setOpen } = useAuthModalContext()
  const router = useRouter()
  const { action } = router.query
  const actionAsParamAction = action as QueryParamAction

  useEffect(() => {
    switch (actionAsParamAction) {
      case 'login':
        // TODO: Does not make sense if user is logged in, but UX is bad if the effect is coming from a button, which does nothing then.
        setOpen(true)
        break
    }
  }, [actionAsParamAction, loggedIn, setOpen])

  return null
}
