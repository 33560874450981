import { ApolloClient, InMemoryCache } from '@apollo/client'
import type { CreateApolloClientOptions } from './CreateApolloClientOptions'
import { createMainLink } from './MainLink'

export const createApolloClient = (options: CreateApolloClientOptions) => {
  const ssrMode = options.ssrMode ?? typeof window === 'undefined'
  const { possibleTypes, fetchPolicy = ssrMode ? 'network-only' : undefined } =
    options
  return new ApolloClient({
    ssrMode,
    link: createMainLink(options),
    cache: new InMemoryCache({
      typePolicies: {
        myaccentro_favorites: {
          keyFields: ['propertyId', 'userId'],
        },
        propstack_property: {
          keyFields: ['locale', 'id'],
        },
      },
      possibleTypes,
    }),
    defaultOptions: {
      query: {
        fetchPolicy,
      },
    },
  })
}
