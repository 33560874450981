import { useAuthModalContext } from '@dreamstack/auth'
import { useTranslation } from '@dreamstack/i18n'
import { SimpleInput } from '@dreamstack/simple-components'
import type { FunctionComponent } from 'react'
import type { Control, FieldValues, Path, PathValue } from 'react-hook-form'
import { Controller, useFormContext } from 'react-hook-form'

export const ControlledEmail = <T extends FieldValues>({
  control,
  className,
  defaultValue,
}: React.PropsWithChildren<{
  control: Control<T>
  className?: string
  defaultValue?: PathValue<T, Path<T>>
}>) => {
  const t = useTranslation()
  return (
    <Controller
      defaultValue={defaultValue}
      name={'email' as Path<T>}
      control={control}
      render={({ field }) => (
        <SimpleInput
          {...field}
          className={className}
          autoFocus
          id="email"
          label={t('accentro:auth.enterEmail')}
          fullWidth
          inputProps={{ autoCapitalize: 'none' }}
        />
      )}
    />
  )
}

export const UnControlledEmail: FunctionComponent<
  React.PropsWithChildren<{
    className?: string
    defaultValue?: string
    id?: string
  }>
> = ({ className, defaultValue = null, id = 'email' }) => {
  const t = useTranslation()
  const { register } = useFormContext()

  return (
    <>
      <SimpleInput
        {...register('email')}
        className={className}
        id={id}
        label={t('accentro:e_mail')}
        fullWidth
      />
    </>
  )
}

export const ControlledAuthEmail = <T extends FieldValues>({
  control,
  className,
}: React.PropsWithChildren<{
  control: Control<T>
  className?: string
}>) => {
  const { email } = useAuthModalContext()
  return (
    <ControlledEmail
      control={control}
      defaultValue={email as PathValue<T, Path<T>>}
      className={className}
    />
  )
}
