import { useTranslation } from '@dreamstack/i18n'
import type { FunctionComponent } from 'react'
import tw from 'twin.macro'
import { BodyText, GridWithGapsAndPadding, HeadingH1 } from '../index'
import { useNewsletterForm } from './useNewsletterForm'

type NewsletterFormData = {
  title: string
  foreame: string
  surname: string
  agreeToLegalImprint: boolean
  lang: 'de' | 'en'
  email: string
}

export const newsletterInputClassName = 'newsletter-email-input' as const

export const NewsletterWrapper = tw.div`bg-accentroGray-full text-accentroWhite-full`
const FormFieldsWrapper = tw.div`grid grid-cols-1 gap-px-32 mt-px-24 md:(grid-cols-2 mt-px-48 gap-px-48) lg:(mt-px-96 gap-px-64) `
const NewsletterForm = tw.div`col-span-full`
const Divider = tw.div`h-px bg-accentroWhite-500 mt-px-80 lg:mt-px-96`

export const Newsletter: FunctionComponent<React.PropsWithChildren<{}>> = () => {
  const t = useTranslation()
  const {
    FormFields,
    formProps,
    FormProvider,
    formProviderProps,
    SuccessDialog,
  } = useNewsletterForm()
  // TODO: Prefill form data if logged in.
  return (
    <NewsletterWrapper>
      {SuccessDialog}
      <GridWithGapsAndPadding tw="mt-px-64 md:mt-px-80 ml:mt-px-96">
        <NewsletterForm>
          <FormProvider {...formProviderProps}>
            <form {...formProps}>
              <HeadingH1 id="newsletter" tw="mt-px-48 ml:mt-px-80 lg:mt-px-128">
                {t('accentro:newsletter')}
              </HeadingH1>
              <FormFieldsWrapper>
                <div>
                  <BodyText>{t('accentro:formTexts.newsletterBody')}</BodyText>
                </div>
                {FormFields}
              </FormFieldsWrapper>
            </form>
          </FormProvider>
          <Divider />
        </NewsletterForm>
      </GridWithGapsAndPadding>
    </NewsletterWrapper>
  )
}
