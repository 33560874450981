import type { FunctionComponent} from 'react';
import React, { createContext, useContext } from 'react'

export type IMainLayoutContext = {
  newsLetterFooter?: JSX.Element
  contactFooter?: JSX.Element
}
const MainLayoutContext = createContext({} as IMainLayoutContext)

export const MainLayoutContextProvider: FunctionComponent<React.PropsWithChildren<{
  context: IMainLayoutContext
}>> = ({ children, context }) => {
  return (
    <MainLayoutContext.Provider value={context}>
      {children}
    </MainLayoutContext.Provider>
  )
}

export const useMainLayoutContext = () => {
  return useContext(MainLayoutContext)
}
