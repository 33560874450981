import { transports } from 'winston'

export class WaitForRequestsSettledHttpTransport extends transports.Http {
  private requestsSend = 0
  private requestsSettled = 0

  constructor(options = {}) {
    super(options)

    // logged --> request returned 200
    // warn --> request had error
    this.on('logged' as any, () => this.requestsSettled++)
    this.on('warn' as any, () => this.requestsSettled++)
  }

  log(info: any, callback: any) {
    this.requestsSend++
    if (super.log) {
      super.log(info, callback)
    }
  }

  areRequestsSettled() {
    return this.requestsSend === this.requestsSettled
  }

  waitForRequestsSettled(timeoutInMs = 30_000) {
    const startedAt = Date.now()
    return new Promise((res, rej) => {
      const intervalId = setInterval(() => {
        if (this.areRequestsSettled()) {
          res(null)
          clearInterval(intervalId)
        }

        const now = Date.now()
        if (now - startedAt >= timeoutInMs) {
          rej(new Error('timeout'))
          clearInterval(intervalId)
        }
      }, 50)
    })
  }
}
