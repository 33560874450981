import type { AuthStep} from '@dreamstack/auth';
import { useAuthModalContext } from '@dreamstack/auth'
import { ArrowLeft } from '@dreamstack/icons'
import type { FunctionComponent } from 'react'
import 'twin.macro'

export const AuthBackButton: FunctionComponent<React.PropsWithChildren<{ toStep: AuthStep }>> = ({
  toStep,
}) => {
  const { setActiveStep } = useAuthModalContext()
  return (
    <ArrowLeft
      onClick={() => setActiveStep(toStep)}
      tw="w-px-40 h-px-40 mr-px-16 md:mr-px-24 cursor-pointer flex-shrink-0"
    />
  )
}
