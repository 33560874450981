import { useTranslation } from '@dreamstack/i18n'
import { SimpleFieldError } from '@dreamstack/simple-components'
import type { FunctionComponent } from 'react'
import { useCallback, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import tw from 'twin.macro'
import { signInWithEmail } from '../../../../auth/src/lib/actions/signInWithEmail'
import { useAuthModalContext } from '../../../../auth/src/lib/components/AuthModalContext'
import { Button } from '../../layout/Button'
import { LegalImprintCheckbox } from '../../newsletter/formElements/LegalImprintCheckbox'
import { AuthEmailDisplay } from './AuthEmailDisplay'
import type { LoginFormData } from './Login'

type LoginFormWithConsentData = LoginFormData & {
  accentroTermsConsent: boolean
}
const StyledForm = tw.form`space-y-px-40 ml:space-y-px-48`

export const AccentroTermsConsentWithPassword: FunctionComponent<React.PropsWithChildren<{}>> = () => {
  const t = useTranslation()
  const [termsAccepted, setTermsAccepted] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)

  const { setActiveStep, email } = useAuthModalContext()

  const onSubmit = useCallback(async () => {
    if (!termsAccepted) {
      setErrorMessage(t('accentro:legalImprintRequired'))
      return
    }
    setErrorMessage('')
    setLoading(true)
    const signInResponse = await signInWithEmail({
      email,
    })
    const success = !!signInResponse?.ok
    setLoading(false)
    success
      ? setActiveStep('ConfirmationEmailSent')
      : setErrorMessage(t('accentro:auth.login.error'))
  }, [email, setActiveStep, t, termsAccepted])
  const methods = useForm<LoginFormWithConsentData>()
  const { handleSubmit } = methods

  return (
    <>
      <FormProvider {...methods}>
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <LegalImprintCheckbox
            checked={termsAccepted}
            onChangeCallback={setTermsAccepted}
            name="consent"
          />
          <AuthEmailDisplay />
          {!!errorMessage && (
            <SimpleFieldError>{errorMessage}</SimpleFieldError>
          )}
          <div tw="flex flex-col gap-px-12 md:flex-row">
            <Button
              variant="contained"
              type="submit"
              color="primary"
              tw="w-auto"
              loading={loading}
              disabled={loading || !termsAccepted}
            >
              {t('accentro:auth.requestLoginLink')}
            </Button>
            <Button
              variant="outlined"
              type="button"
              color="secondary"
              disabled={!termsAccepted}
              onClick={() => setActiveStep('loginWithPassword')}
            >
              {t('accentro:auth.enterPassword')}
            </Button>
          </div>
        </StyledForm>
      </FormProvider>
    </>
  )
}
