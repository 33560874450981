import { appWithTranslation } from 'next-i18next'
import type { AppProps } from 'next/app.js'
import nextI18NextConfig from '../../next-i18next.config.js'

const TranslatorRaw = ({ children }: React.PropsWithChildren<AppProps>) => {
  return <>{children}</>
}

export const Translator: any = appWithTranslation(
  TranslatorRaw,
  nextI18NextConfig
)
