import type {
  UserView} from './recommendationTypes';
import {
  RECOMMENDATION_LOCAL_STORAGE_IDENTIFIER
} from './recommendationTypes'

export const trackPageViewForProperty = ({ id }: { id: number }) => {
  let current
  try {
    const localStorageItem = localStorage.getItem(
      RECOMMENDATION_LOCAL_STORAGE_IDENTIFIER
    )
    if (!!localStorageItem)
      current = JSON.parse(
        localStorage.getItem(RECOMMENDATION_LOCAL_STORAGE_IDENTIFIER) ?? ''
      )
  } catch (e) {}
  if (!current) current = []
  if (current[current.length - 1]?.id === id) return
  if (current?.length >= 10) current.shift()
  current.push({
    id: id,
    visited_at: new Date(),
  })
  localStorage.setItem(
    RECOMMENDATION_LOCAL_STORAGE_IDENTIFIER,
    JSON.stringify(current)
  )
}

export const getLocalPageViews = () => {
  const lastPagesViewedStorage = localStorage.getItem(
    RECOMMENDATION_LOCAL_STORAGE_IDENTIFIER
  )
  if (!lastPagesViewedStorage) return null

  const lastPagesViewedFn = (): UserView[] => {
    try {
      return JSON.parse(lastPagesViewedStorage)
    } catch (e) {
      return []
    }
  }

  const lastPagesViewed = lastPagesViewedFn()
  return lastPagesViewed
}
