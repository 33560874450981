import { ApolloProvider as ApolloProviderRaw } from '@apollo/client'
import type { CreateApolloClientOptions } from '@dreamstack/artemis'
import { usePreviewSecret } from '@dreamstack/artemis'
import { getToken } from '@dreamstack/auth'
import { useLocale } from '@dreamstack/i18n'
import { useCreateApolloClient } from '@dreamstack/web-graphql'
import type { FunctionComponent } from 'react'
import { useMemo } from 'react'

export const ApolloProvider: FunctionComponent<
  React.PropsWithChildren<{ pageProps: any }>
> = ({ pageProps, children }) => {
  const locale = useLocale()
  const previewModeSecret = usePreviewSecret()

  const { initialApolloState } = pageProps
  const apolloOptions = useMemo(
    () =>
      ({
        initialState: initialApolloState,
        locale,
        previewModeSecret,
        getAuthToken: () => getToken(),
        ssrMode: false,
      } as CreateApolloClientOptions),
    [initialApolloState, locale, previewModeSecret]
  )

  const apolloClient = useCreateApolloClient(apolloOptions)

  return (
    <>
      <ApolloProviderRaw client={apolloClient}>{children}</ApolloProviderRaw>
    </>
  )
}
