import { setContext } from '@apollo/client/link/context'
import forEach from 'lodash/forEach'
import type { CreateApolloClientOptions } from '../apollo/CreateApolloClientOptions'
import { getDefaultQueryVariables } from './getDefaultQueryVariables'

export const createDefaultVarsLink = ({
  locale,
  previewModeSecret,
}: CreateApolloClientOptions) => {
  const defaultQueryVariables = getDefaultQueryVariables({
    locale,
    preview: !!previewModeSecret,
  })

  // const forcedVars = {}

  const defaultVarsLink = setContext((ctx, { headers }) => {
    // TODO: make this in a immutable way
    ctx.variables = ctx.variables || {}
    const variables = ctx.variables
    forEach(defaultQueryVariables, (value, key) => {
      if (typeof variables[key] === 'undefined') {
        if (typeof value !== 'undefined') {
          variables[key] = value
        }
      }
    })

    // forEach(forcedVars, (value, key) => {
    //   if (typeof value !== 'undefined') {
    //     variables[key] = value
    //   }
    // })
    return {}
  })

  return defaultVarsLink
}
