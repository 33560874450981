import type { CommonNode, Options } from '@contentful/rich-text-react-renderer'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types'
import type {
  FullAssetFragment,
  FullHyperlinkFragment,
  Maybe,
  Scalars,
} from '@dreamstack/investors-graphql'
import { generateIdForText } from '@dreamstack/util'
import find from 'lodash/find'
import map from 'lodash/map'
import Link from 'next/link'
import type { HTMLAttributes, ReactNode } from 'react'
import { createElement } from 'react'
import tw, { css, styled } from 'twin.macro'
import type { additionAttributes } from '../types/ankerAttribute'
import { scrollToTarget } from '../utils/scrollToTarget'
import { EmbeddedAsset } from './EmbeddedAsset'
import { EmbeddedAssetHyperlink } from './EmbeddedAssetHyperlink'

const WHITELIST_FOLLOWED_LINKS = ['proprate.de']

const TextSemiBoldWrapper = styled.span(() => [tw`font-semibold`])

// Take the first three words of the heading at max to generate the id.
const generateIdForHeadingNode = (node: any) => {
  const hasContent = (node?.content?.length ?? 0) > 0
  if (!hasContent) return ''

  const content = node?.content

  // TODO: Set correct id for nodeType assetlink.
  const hyperlink = find(content, (c) => c.nodeType === INLINES.HYPERLINK)
  const hyperlinkValue = hyperlink?.content
    ? hyperlink.content[0].value
    : undefined
  const headingValue = content[0].value

  const heading = hyperlinkValue || headingValue
  return generateIdForText(heading)
}

const getHeadingComponent = (
  headingLevel: 'h1' | 'h2' | 'h3' | 'h4',
  node: CommonNode,
  children: ReactNode
): ReactNode | string => {
  const Heading = (props: HTMLAttributes<HTMLHeadingElement>) => {
    return createElement(headingLevel, props)
  }

  const id = generateIdForHeadingNode(node)
  return <Heading id={id}>{children}</Heading>
}

// FROM: https://github.com/whitep4nth3r/p4nth3rblog/blob/main/components/RichTextPageContent/index.js#L17-L23
function getRichTextRenderOptions(links: RichTextProps['links']) {
  const assetBlockMap = new Map<string, FullAssetFragment | null>(
    map(links?.assets?.block, (asset) => [asset?.sys?.id ?? '', asset])
  )
  const hyperlinkBlockMap = new Map<string, FullHyperlinkFragment | null>(
    map(links?.assets?.hyperlink, (link) => [link?.sys?.id ?? '', link])
  )
  return {
    renderText: function TextComponent(text: string) {
      // TODO: is there a way to do dangerouslySetInnerHTML without creating a span dom node?
      return <span dangerouslySetInnerHTML={{ __html: text }}></span>
    },
    renderMark: {
      [MARKS.BOLD]: function BoldText(node) {
        return <TextSemiBoldWrapper>{node}</TextSemiBoldWrapper>
      },
    },
    //   [MARKS.ITALIC]: function ItalicText(node) {
    //     console.log('ITALIC', node)
    //     return <i>{node}</i>
    //   },
    // },
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => {
        return getHeadingComponent('h1', node, children)
      },
      [BLOCKS.HEADING_2]: (node, children) => {
        return getHeadingComponent('h2', node, children)
      },
      [BLOCKS.HEADING_3]: (node, children) => {
        return getHeadingComponent('h3', node, children)
      },
      [BLOCKS.HEADING_4]: (node, children) => {
        return getHeadingComponent('h4', node, children)
      },
      [BLOCKS.EMBEDDED_ASSET]: (node, next) => {
        const asset = assetBlockMap.get(node.data.target.sys.id)
        const embeddedAssetComponent = asset ? (
          <EmbeddedAsset asset={asset} />
        ) : null
        return embeddedAssetComponent
      },
      [INLINES.HYPERLINK]: (node, children) => {
        const href: string = node.data.uri || ''
        const isJavascript = href.startsWith('javascript:')
        const isAnchor = href.startsWith('#')
        const isOnPageLink =
          href.startsWith('#') ||
          href.startsWith('/') ||
          href.includes('accentro.de')
        const isMailTo = href.startsWith('mailto:')

        const isWhitelistedFollowedLink = WHITELIST_FOLLOWED_LINKS.some(
          (whitelistedLink) => href.includes(whitelistedLink)
        )

        const target = isJavascript || isOnPageLink ? undefined : '_blank'
        const rel =
          isJavascript || isOnPageLink || isWhitelistedFollowedLink
            ? undefined
            : 'nofollow noreferrer'

        const additionAttributes: additionAttributes = {}

        if (isAnchor) {
          additionAttributes.onClick = (
            event?: React.MouseEvent<Element, MouseEvent> | undefined
          ) => scrollToTarget(href, event)
        }

        // Transforms the href to a relative path if it is an internal link.
        const transformedHref = href.includes('accentro.de')
          ? href.split('accentro.de')[1] ?? '/'
          : href

        // If the link is a mailto link, we don't want to use the transformed one.
        const parsedHref = isMailTo ? href : transformedHref

        const hyperlinkComponent = (
          <Link
            href={parsedHref}
            passHref
            target={target}
            rel={rel}
            {...additionAttributes}
          >
            {children}
          </Link>
        )
        return hyperlinkComponent
      },
      [INLINES.ASSET_HYPERLINK]: (node, children) => {
        const link = hyperlinkBlockMap.get(node.data.target.sys.id)
        const assetHyperlinkComponent = link ? (
          <EmbeddedAssetHyperlink hyperlinkAsset={link}>
            {children}
          </EmbeddedAssetHyperlink>
        ) : null
        return assetHyperlinkComponent
      },
      // [BLOCKS.HEADING_1]: (node, children) => <HeadingH1>{children}</HeadingH1>,
      // [BLOCKS.HEADING_2]: (node, children) => <HeadingH2>{children}</HeadingH2>,
      // [BLOCKS.HEADING_3]: (node, children) => <HeadingH3>{children}</HeadingH3>,
      // [BLOCKS.HEADING_4]: (node, children) => <HeadingH4>{children}</HeadingH4>,
      // [BLOCKS.PARAGRAPH]: (node, next) => {
      //   console.log({ node, next })
      //   return (
      //     <p>
      //       {node?.content?.[0]?.value?.split(/\n/g).map((v, i) => (
      //         <Fragment key={i}>
      //           {v}
      //           {v && <br />}
      //         </Fragment>
      //       ))}
      //     </p>
      //   )
      //   //node.content.replace('\n', '<br/>')
      // },
    },
  } as Options
}

type RichTextProps = {
  json: Scalars['JSON']
} & {
  links?: { __typename?: string } & {
    assets: { __typename?: string } & {
      block: Array<Maybe<{ __typename?: 'Asset' } & FullAssetFragment>>
      hyperlink: Array<Maybe<{ __typename?: 'Asset' } & FullHyperlinkFragment>>
    }
  }
}

export const RichtextWrapper = styled.div(
  ({
    forceWhiteFont,
    textAlignCenter,
  }: {
    forceWhiteFont?: boolean | null
    textAlignCenter?: boolean | null
  }) => [
    tw`prose prose-accentro md:prose-accentro-md ml:prose-accentro-ml whitespace-pre-line w-full`,
    textAlignCenter && tw`text-center`,
    forceWhiteFont ? tw`text-accentroWhite-full!` : tw`text-accentroGray-full!`,
    forceWhiteFont
      ? css`
          li::before {
            ${tw`bg-accentroWhite-full!`}
          }
        `
      : tw``,
    forceWhiteFont
      ? css`
          a {
            ${tw`text-accentroWhite-full!`}
          }
        `
      : tw``,
  ]
)

export const ContentfulRichText = ({
  richText,
  forceWhiteFont,
  textAlignCenter,
  superscript,
  superscriptFontSize,
}: {
  richText?: RichTextProps | null
  textAlignCenter?: boolean | null
  superscript?: string | null
  superscriptFontSize?: string | null
  forceWhiteFont?: boolean | null
}) => {
  if (!richText) return null
  return (
    <>
      {superscript && (
        <div
          style={{
            fontSize: superscriptFontSize ? superscriptFontSize : '100%',
          }}
        >
          {superscript}
        </div>
      )}

      <RichtextWrapper
        forceWhiteFont={forceWhiteFont}
        textAlignCenter={textAlignCenter}
      >
        {documentToReactComponents(
          richText.json,
          getRichTextRenderOptions(richText.links)
        )}
      </RichtextWrapper>
    </>
  )
}
