import { useLocale } from '@dreamstack/i18n'
import type { FunctionComponent} from 'react';
import { useEffect } from 'react'

export const SwitchLocaleForUc: FunctionComponent<React.PropsWithChildren<{}>> = () => {
  const locale = useLocale()
  // Locale switcher for Usercentrics UI.
  useEffect(() => {
    // @ts-ignore: Is defined by external script.
    if (!window?.UC_UI) return
    // @ts-ignore: Is defined by external script.
    window.UC_UI?.updateLanguage(locale)
  }, [locale])
  return <></>
}
