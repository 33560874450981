import type { CheckboxProps, FormControlLabelProps } from '@material-ui/core'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import CheckBoxOutlineBlankSharpIcon from '@material-ui/icons/CheckBoxOutlineBlankSharp'
import CheckBoxSharpIcon from '@material-ui/icons/CheckBoxSharp'
import IndeterminateCheckBoxSharpIcon from '@material-ui/icons/IndeterminateCheckBoxSharp'
import React from 'react'
import tw, { styled } from 'twin.macro'

// const StyledCheckbox = withStyles((theme) => ({}))(Checkbox)

const BaseCheckboxRootStyles = { alignSelf: 'self-start' }

const StyledLabelWrapper = styled.div<{ $isOnDarkBg?: boolean }>(
  ({ $isOnDarkBg }) => [
    tw`padding-top[9px] ml:padding-top[5px] mr-px-16 w-full text-sm md:text-base lg:text-base`,
    !!$isOnDarkBg ? tw`text-accentroWhite-full` : tw`text-accentroGray-full`,
  ]
)

// eslint-disable-next-line react/display-name
export const SimpleCheckbox = React.forwardRef<
  HTMLInputElement,
  {
    formControlProps?: Omit<FormControlLabelProps, 'control'>
    checkBoxProps?: CheckboxProps
    isOnDarkBg?: boolean
  }
>(({ formControlProps, checkBoxProps, isOnDarkBg = false }, ref) => {
  const { ...passedFormControlProps } = formControlProps
  return (
    <FormControlLabel
      style={{ alignItems: 'flex-start' }}
      control={
        <Checkbox
          inputRef={ref || undefined}
          style={
            isOnDarkBg
              ? { color: 'white', ...BaseCheckboxRootStyles }
              : { ...BaseCheckboxRootStyles }
          }
          {...checkBoxProps}
          icon={<CheckBoxOutlineBlankSharpIcon />}
          checkedIcon={<CheckBoxSharpIcon />}
          indeterminateIcon={<IndeterminateCheckBoxSharpIcon />}
        />
      }
      {...passedFormControlProps}
      label={
        formControlProps?.label ? (
          <StyledLabelWrapper
            $isOnDarkBg={isOnDarkBg}
            tw="padding-top[9px] ml:padding-top[5px] mr-px-16 w-full text-sm md:text-base lg:text-base"
          >
            <div tw="ml-px-12 text-px-16 leading-px-24 ml:(text-px-20 leading-px-30)">
              {formControlProps.label}
            </div>
          </StyledLabelWrapper>
        ) : null
      }
    />
  )
})
