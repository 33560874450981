import type { FunctionComponent } from 'react'
import { createGlobalStyle } from 'styled-components'
import 'twin.macro'

export const DotAnimationKeyframes = createGlobalStyle`  @keyframes dot1 {
    0%,15% { 
      opacity: 0;
          }
    25%,95% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes dot2 {
    0%, 40% { 
      opacity: 0;
         }
    50%,95% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes dot3 {
    0%,65%{ 
      opacity: 0;
            }
    75%,95% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  `
export const DotAnimationLoader: FunctionComponent<React.PropsWithChildren<{}>> = ({ children }) => {
  return (
    <>
      <DotAnimationKeyframes />
      <span tw="flex   text-white font-semibold  items-end   justify-center">
        {children}
        <span tw=" animate-loader1 w-px-4 h-px-4 rounded-full bg-white ml-1 mb-[8px] ml:mb-[10px]"></span>
        <span tw=" animate-loader2 w-px-4 h-px-4 rounded-full bg-white ml-1 mb-[8px] ml:mb-[10px]"></span>
        <span tw=" animate-loader3 w-px-4 h-px-4 rounded-full bg-white ml-1 mb-[8px] ml:mb-[10px]"></span>
      </span>
    </>
  )
}
