import type { IAdditionalTrackingInfoContext } from '../src/AdditionalTrackingInfoContext'

declare let window: any
declare let dataLayer: any

// TODO: README.md with architecture & how to add new methods
// TODO: Tests
// TODO: Check if trackingId exists (because it uses process.env)
// TODO: Example hook for custom event
// TODO: Move into own nx lib and disallow deep imports

export const googleAnalytics = (trackingId: string): GoogleAnalytics => {
  // Crazy google analytics function. Has to be a function, not an arrow function
  // Has to use arguments in dataLayer.push. If you use args it won't work
  // ...args is only for TS to not complain.
  // This function is copied directly from google
  function gtag(...args: any) {
    if (typeof window === 'undefined') return

    // eslint-disable-next-line prefer-rest-params
    dataLayer.push(arguments)
  }
  // TODO: fix any typing
  function gtagEvent(data: any) {
    if (typeof window === 'undefined') return

    // eslint-disable-next-line prefer-rest-params
    dataLayer.push(data)
  }

  const setup = () => {
    if (typeof window === 'undefined') return

    window.dataLayer = window.dataLayer || []

    gtag('js', new Date())
    gtag('config', /*trackingId*/ { anonymize_ip: true })
    // gtag('config', trackingId)
  }

  const trackPageView: TrackPageView = ({
    url,
    additionalInfo = {},
  }: {
    url: string
    additionalInfo?: Record<string, any>
  }) => {
    if (typeof window === 'undefined') return

    const page = { path: url, title: document.title, ...additionalInfo }

    // console.info('GA: pageview', page)

    gtagEvent({
      event: 'pageview',
      ...page,
    })
  }

  const trackEvent: TrackEvent = (data) => {
    gtagEvent(data)
  }

  setup()

  return {
    trackPageView,
    trackEvent,
  }
}

type TrackPageView = (options: {
  url: string
  additionalInfo?: IAdditionalTrackingInfoContext
}) => void

type TrackEvent = (data: any) => void

export interface GoogleAnalytics {
  trackPageView: TrackPageView
  trackEvent: TrackEvent
}
