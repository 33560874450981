import { useMyAccentroBaseUrl } from '@dreamstack/feature-components'
import { useRouter } from 'next/router'
import type { FunctionComponent } from 'react'
import { createContext, useCallback, useContext, useState } from 'react'

export type AuthStep =
  | 'loginOrRegister'
  | 'login'
  | 'loginWithPassword'
  | 'resetPassword'
  | 'ThirdPartyAuth'
  | 'ConfirmationEmailSent'
  | 'ConfirmationEmailSentSuccessPage'
  | 'AccentroTermsConsent'
  | 'AccentroTermsConsentWithPassword'

const useAppContextState = () => {
  const [open, setOpen] = useState<boolean>(false)
  const [activeStep, setActiveStep] = useState<AuthStep>('loginOrRegister')
  const [email, setEmail] = useState<string>('')
  const [actionCallback, _setActionCallback] = useState<CallableFunction>()
  const [actionHref, setActionHref] = useState<string>()

  const myAccentroLink = useMyAccentroBaseUrl()

  const setActionCallback = (fn: CallableFunction | undefined) =>
    _setActionCallback(() => () => {
      fn && fn()
    })

  const router = useRouter()

  const pushToIndex = useCallback(async () => {
    await router.push('/', undefined, { shallow: true })
  }, [router])

  const resetModal = useCallback(async () => {
    if (router.asPath.includes(`/${myAccentroLink}`)) {
      await pushToIndex()
    }
    setOpen(false)
    setActiveStep('loginOrRegister')
    setEmail('')
    setActionCallback(undefined)

    // Reset query params, if the Authmodal was openend by a query param.
    const { action } = router.query
    // TODO: action should be type of QueryParamAction from apps/next/components/SideEffects/QueryParamActionHandler.tsx. Not used here because of circular dependency.
    if (action === 'login') {
      await router.push(`${router.asPath.split('?')[0]}`, undefined, {
        shallow: true,
      })
    }
  }, [pushToIndex, router, myAccentroLink])

  const resolveActions = useCallback(async () => {
    actionCallback && (await actionCallback())
    actionHref && router.push(actionHref)
    resetModal()
  }, [actionCallback, actionHref, resetModal, router])

  return {
    open,
    setOpen,
    activeStep,
    setActiveStep,
    email,
    setEmail,
    actionCallback,
    setActionCallback,
    actionHref,
    setActionHref,
    resetModal,
    resolveActions,
  }
}

type IAuthModalContext = ReturnType<typeof useAppContextState>

export const AuthModalContext = createContext({} as IAuthModalContext)

export const AuthModalContextProvider: FunctionComponent<React.PropsWithChildren<unknown>> = ({ children }) => {
  const context: IAuthModalContext = useAppContextState()
  return (
    <AuthModalContext.Provider value={context}>
      {children}
    </AuthModalContext.Provider>
  )
}

export function useAuthModalContext() {
  return useContext(AuthModalContext)
}
