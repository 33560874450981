import Link from 'next/link'
import type { ButtonHTMLAttributes, MouseEvent, ReactElement } from 'react'
import { useState } from 'react'
import tw, { styled } from 'twin.macro'
import { DotAnimationLoader } from './DotAnimationLoader'

type Color = 'primary' | 'secondary' | 'warn'
type Size = 'xsmall' | 'small' | 'medium' | 'large'
type Variant = 'contained' | 'outlined' | 'text'

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  href?: string
  target?: '_blank' | '_self'
  color: Color
  size?: Size
  disabled?: boolean
  variant: Variant
  loading?: boolean
  loadingText?: string
  startIcon?: React.ReactNode
  endIcon?: React.ReactNode
  children?: string | ReactElement | ReactElement[] | null
}

const ButtonBase = styled.button<{
  disabled?: boolean
  color: Color
  size?: Size
  variant: Variant
}>(({ disabled, color, size = 'large', variant }) => {
  const padding =
    variant === 'text'
      ? ''
      : size === 'xsmall'
      ? tw`pl-px-12 pr-px-12 pt-px-8 pb-px-8`
      : size === 'small'
      ? tw`pl-px-16 pr-px-16 pt-px-12 pb-px-12`
      : tw`pl-px-24 pr-px-24 pt-px-16 pb-px-16
           ml:pl-px-24 ml:pr-px-24 ml:pt-px-20 ml:pb-px-20`

  const fontSize =
    size === 'xsmall'
      ? tw`text-sm`
      : size === 'small'
      ? tw`text-base`
      : size === 'large'
      ? tw`text-base ml:text-xl`
      : ''

  const border = variant === 'text' ? '' : tw` border-2`

  const borderColor = [
    variant === 'contained'
      ? color === 'warn'
        ? tw`hover:border-[#a8381b]`
        : color === 'primary'
        ? tw`hover:border-[#385964]`
        : color === 'secondary'
        ? tw`hover:border-[#24262d]`
        : ''
      : '',
    disabled
      ? variant === 'contained'
        ? tw`border-transparent hover:border-transparent`
        : tw`border-accentroGray-300`
      : color === 'warn'
      ? tw`border-accentroError-full`
      : color === 'primary'
      ? tw`border-accentroAqua-full`
      : color === 'secondary'
      ? tw`border-accentroGray-full`
      : '',
  ]

  const bgColor = [
    disabled
      ? variant === 'contained'
        ? tw`bg-accentroGray-300`
        : ''
      : [
          color === 'warn'
            ? tw`bg-accentroError-full`
            : color === 'primary'
            ? tw`bg-accentroAqua-full`
            : color === 'secondary'
            ? tw`bg-accentroGray-full`
            : '',
        ],
    !disabled &&
      (variant === 'text'
        ? tw`bg-opacity-0`
        : variant === 'outlined'
        ? tw`bg-opacity-0 hover:bg-opacity-[.15]`
        : variant === 'contained'
        ? [
            color === 'warn'
              ? tw`hover:bg-[#a8381b]`
              : color === 'primary'
              ? tw`hover:bg-[#385964]`
              : color === 'secondary'
              ? tw`hover:bg-[#24262d]`
              : '',
          ]
        : ''),
  ]

  const textColor = disabled
    ? variant === 'contained'
      ? tw`text-white`
      : tw`text-accentroGray-300`
    : variant === 'contained'
    ? tw`text-white`
    : color === 'warn'
    ? tw`text-accentroError-full`
    : color === 'primary'
    ? tw`text-accentroAqua-full`
    : color === 'secondary'
    ? tw`text-accentroGray-full`
    : ''

  return [
    tw`font-semibold transition-all focus:outline-none overflow-hidden relative`,
    padding,
    fontSize,
    border,
    borderColor,
    bgColor,
    textColor,
  ]
})

/**
 * Use within `<Button>` or another div with className "group",
 * e.g. as `endIcon` to apply a bouncing animation on hover.
 */
export const AnimateBounceRight = tw.div`group-hover:animate-bounceRight`

/**
 * Use within `<Button>` or another div with className "group",
 * e.g. as `startIcon` to apply a bouncing animation on hover.
 */
export const AnimateBounceLeft = tw.div`group-hover:animate-bounceLeft`

const Ripple = tw.div`absolute h-3 w-3 bg-[#ffffffb3] rounded-full animate-ripple`

const useRipple = () => {
  const [ripple, setRipple] = useState(false)
  const [rippleLeft, setRippleLeft] = useState(0)
  const [rippleTop, setRippleTop] = useState(0)

  const rippleEvent = (e: MouseEvent<HTMLButtonElement, any>) => {
    const button = e.currentTarget
    const viewportOffset = button.getBoundingClientRect()
    const left = e.clientX - viewportOffset.left - 8
    const top = e.clientY - viewportOffset.top - 8
    setRippleLeft(left)
    setRippleTop(top)
    setTimeout(() => {
      setRipple(true)
    }, 0)
    setTimeout(() => {
      setRipple(false)
    }, 300)
  }

  const rippleComponent = ripple ? (
    <Ripple style={{ left: `${rippleLeft}px`, top: `${rippleTop}px` }} />
  ) : null

  return {
    Ripple: rippleComponent,
    rippleEvent,
  }
}

export const Button = (props: Props) => {
  const { rippleEvent, Ripple } = useRipple()

  const button = (
    <ButtonBase
      className="group"
      {...props}
      onClick={async (e) => {
        if (props.variant !== 'text') rippleEvent(e)
        props.onClick?.(e)
      }}
    >
      <div tw="flex items-center">
        {!!props.startIcon && (
          <div tw="mr-px-16 ml:mr-px-20 -ml-px-4">{props.startIcon}</div>
        )}
        {!!props.loading ? (
          <DotAnimationLoader>
            {!!props.loadingText ? props.loadingText : props.children}
          </DotAnimationLoader>
        ) : (
          props.children
        )}
        {!!props.endIcon && (
          <div tw="ml-px-16 ml:ml-px-20 -mr-px-4">{props.endIcon}</div>
        )}
      </div>
      {Ripple}
    </ButtonBase>
  )

  return props.href && !props.disabled ? (
    <Link href={props.href} passHref target={props.target ?? '_self'}>
      {button}
    </Link>
  ) : (
    button
  )
}
