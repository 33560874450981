import type { FunctionComponent } from 'react'
import { QueryParamActionHandler } from '../SideEffects/QueryParamActionHandler'
import { SwitchLocaleForUc } from '../SideEffects/SwitchLocaleForUc'
import { SyncUserEngagements } from '../SideEffects/SyncUserEngagements'

export const MainSideEffects: FunctionComponent<React.PropsWithChildren<{}>> = () => {
  return (
    <>
      <SwitchLocaleForUc />
      <SyncUserEngagements />
      <QueryParamActionHandler />
    </>
  )
}
