import { useCallback } from 'react'
import { useGoogleAnalytics } from './GoogleAnalyticsContext'

export const useGaTrackEvent = () => {
  const googleAnalytics = useGoogleAnalytics()
  const trackEvent = googleAnalytics?.trackEvent
  return trackEvent
}

export const useGaTrackFormSubmission = () => {
  const trackEvent = useGaTrackEvent()
  return useCallback(
    ({ formId, formData }: { formId: string; formData: any }) => {
      if (!trackEvent) return
      return trackEvent({
        event: 'requestSubmission',
        formId,
        formData,
      })
    },
    [trackEvent]
  )
}

export const useGaTrackPropstackContactId = () => {
  const trackEvent = useGaTrackEvent()
  return useCallback(
    ({ propstackContactId }: { propstackContactId: number }) => {
      if (!trackEvent) return
      return trackEvent({
        event: 'propstackContactId',
        propstackContactId,
      })
    },
    [trackEvent]
  )
}
