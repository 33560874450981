import { BASE_URL } from '@dreamstack/feature-components'
import { useLocale } from '@dreamstack/i18n'
import { DefaultSeo } from 'next-seo'
import type { FunctionComponent } from 'react'

export const SeoDefaults: FunctionComponent<React.PropsWithChildren<{
  siteTitle: string
  enableSeoFollowIndex?: boolean
}>> = ({ siteTitle }) => {
  const locale = useLocale()
  const enableFollowIndex =
    process.env.NEXT_PUBLIC_ENABLE_SEO_FOLLOW_INDEX === 'true'
  return (
    <DefaultSeo
      dangerouslySetAllPagesToNoFollow={!enableFollowIndex}
      dangerouslySetAllPagesToNoIndex={!enableFollowIndex}
      titleTemplate={`%s — ${siteTitle}`}
      defaultTitle={`${siteTitle}`}
      openGraph={{
        type: 'website',
        locale,
        url: BASE_URL,
        site_name: siteTitle,
      }}
      // twitter={{
      //   handle: '@handle',
      //   site: '@site',
      //   cardType: 'summary_large_image',
      // }}
    />
  )
}
