const plugin = require('tailwindcss/plugin')
module.exports = {
  theme: {
    screens: {
      sm: '660px',
      md: '880px',
      ml: '1366px',
      lg: '1920px',
      xl: '1920px',
    },
    extend: {
      transitionDuration: {
        5000: '5000ms',
      },
      animation: {
        loader1: 'dot1 2s ease-in-out infinite normal ',
        loader2: 'dot2 2s ease-in-out infinite normal ',
        loader3: 'dot3 2s ease-in-out infinite normal ',
      },
      fontFamily: {
        montserrat: ['Montserrat', 'ui-sans-serif'],
      },
      minHeight: {
        'px-96': '96px',
        'px-128': '128px',
        'px-196': '196px',
      },
      maxWidth: {
        'px-96': '96px',
        'px-128': '128px',
        'px-158': '158px',
        'px-196': '196px',
      },
      height: {
        'px-1080': '1080px',
        'px-860': '860px',
        'px-576': '576px',
        'px-480': '480px',
        'px-320': '320px',
      },
      fontSize: {
        'px-96': '96px',
        'px-72': '72px',
        'px-56': '56px',
        'px-48': '48px',
        'px-40': '40px',
        'px-32': '32px',
        'px-28': '28px',
        'px-24': '24px',
        'px-20': '20px',
        'px-16': '16px',
        'px-13': '13px',
        'px-11': '11px',
        'px-9_4': '9.4px',
      },
      lineHeight: {
        'px-120': '120px',
        'px-80': '80px',
        'px-70': '70px',
        'px-60': '60px',
        'px-50': '50px',
        'px-48': '48px',
        'px-44': '44px',
        'px-42': '42px',
        'px-36': '36px',
        'px-30': '30px',
        'px-24': '24px',
        'px-18': '18px',
        'px-14_1': '14.1px',
      },

      letterSpacing: {
        0: '0',
      },
      typography: (theme) => ({
        // TODO: Figure out how to set up defaults.
        DEFAULT: {
          css: {
            h1: {
              color: 'inherit',
              '> b': {
                fontWeight: 600,
              },
            },
            h2: {
              color: 'inherit',
              '> b': {
                fontWeight: 600,
              },
            },
            h3: {
              color: 'inherit',
              '> b': {
                fontWeight: 600,
              },
            },
            h4: {
              color: 'inherit',
              '> b': {
                fontWeight: 600,
              },
            },
            'ul > li::before': {
              backgroundColor: theme('colors.accentroGray.full'),
            },
          },
        },
        accentro: {
          css: {
            // marginBottom: theme('spacing.px-80'),
            maxWidth: 'none',
            color: 'inherit',
            h1: {
              fontSize: theme('fontSize.px-28'),
              letterSpacing: theme('letterSpacing.0'),
              lineHeight: theme('lineHeight.px-42'),
              marginBottom: theme('spacing.px-24'),
              marginTop: theme('spacing.px-64'),
              fontWeight: 600,
            },
            h2: {
              fontSize: theme('fontSize.px-24'),
              letterSpacing: theme('letterSpacing.0'),
              lineHeight: theme('lineHeight.px-36'),
              marginTop: theme('spacing.px-64'),
              fontWeight: 600,
            },
            h3: {
              fontSize: theme('fontSize.px-20'),
              letterSpacing: theme('letterSpacing.0'),
              lineHeight: theme('lineHeight.px-30'),
              marginTop: theme('spacing.px-64'),
              fontWeight: 600,
            },
            h4: {
              fontSize: theme('fontSize.px-20'),
              letterSpacing: theme('letterSpacing.0'),
              lineHeight: theme('lineHeight.px-30'),
              marginTop: theme('spacing.px-64'),
              fontWeight: 600,
            },
            p: {
              '> a': {
                textDecoration: 'none',
                color: theme('colors.accentroAqua.full'),
                '&:hover': {
                  textDecoration: 'underline',
                },
              },
              fontSize: theme('fontSize.px-16'),
              letterSpacing: theme('letterSpacing.0'),
              marginTop: theme('spacing.px-24'),
              lineHeight: theme('lineHeight.px-24'),
              wordWrap: 'break-word',
            },

            blockquote: {
              paddingLeft: 0,
              borderLeft: 'none',
              '> p': {
                fontSize: theme('fontSize.px-16'),
                lineHeight: theme('lineHeight.px-24'),
              },
              'ul > li::before': {
                backgroundColor: theme('colors.accentroGray.full'),
              },
              '~ h5': {
                color: theme('colors.accentroAqua.full'),
                fontWeight: 600,
                fontSize: theme('fontSize.px-20'),
                lineHeight: theme('lineHeight.px-30'),
                margin: 0,
                '~ p': {
                  marginTop: 0,
                },
              },
            },
          },
        },
        'accentro-md': {
          css: {
            maxWidth: 'none',
            color: 'inherit',
            h1: {
              fontSize: theme('fontSize.px-40'),
              letterSpacing: theme('letterSpacing.0'),
              lineHeight: theme('lineHeight.px-50'),
              marginBottom: theme('spacing.px-24'),
              marginTop: theme('spacing.px-64'),
              fontWeight: 600,
            },
            h2: {
              fontSize: theme('fontSize.px-28'),
              letterSpacing: theme('letterSpacing.0'),
              lineHeight: theme('lineHeight.px-42'),
              marginTop: theme('spacing.px-64'),
              fontWeight: 600,
            },
            h3: {
              fontSize: theme('fontSize.px-24'),
              letterSpacing: theme('letterSpacing.0'),
              lineHeight: theme('lineHeight.px-36'),
              marginTop: theme('spacing.px-64'),
              fontWeight: 600,
            },
            h4: {
              fontSize: theme('fontSize.px-20'),
              letterSpacing: theme('letterSpacing.0'),
              lineHeight: theme('lineHeight.px-30'),
              marginTop: theme('spacing.px-64'),
              fontWeight: 600,
            },
            p: {
              '> a': {
                textDecoration: 'none',
                color: theme('colors.accentroAqua.full'),
                '&:hover': {
                  textDecoration: 'underline',
                },
              },
              fontSize: theme('fontSize.px-16'),
              letterSpacing: theme('letterSpacing.0'),
              marginTop: theme('spacing.px-24'),
              lineHeight: theme('lineHeight.px-24'),
              wordWrap: 'break-word',
            },

            blockquote: {
              paddingLeft: 0,
              borderLeft: 'none',
              '> p': {
                fontSize: theme('fontSize.px-16'),
                lineHeight: theme('lineHeight.px-24'),
              },
              'ul > li::before': {
                backgroundColor: theme('colors.accentroGray.full'),
              },
              '~ h5': {
                color: theme('colors.accentroAqua.full'),
                fontWeight: 600,
                fontSize: theme('fontSize.px-20'),
                lineHeight: theme('lineHeight.px-30'),
                margin: 0,
                '~ p': {
                  marginTop: 0,
                },
              },
            },
          },
        },
        'accentro-ml': {
          css: {
            maxWidth: 'none',
            color: 'inherit',
            h1: {
              fontSize: theme('fontSize.px-56'),
              letterSpacing: theme('letterSpacing.0'),
              lineHeight: theme('lineHeight.px-70'),
              marginBottom: theme('spacing.px-32'),
              marginTop: theme('spacing.px-80'),
              fontWeight: 600,
            },
            h2: {
              fontSize: theme('fontSize.px-40'),
              letterSpacing: theme('letterSpacing.0'),
              lineHeight: theme('lineHeight.px-50'),
              marginTop: theme('spacing.px-80'),
              fontWeight: 600,
            },
            h3: {
              fontSize: theme('fontSize.px-28'),
              letterSpacing: theme('letterSpacing.0'),
              lineHeight: theme('lineHeight.px-42'),
              marginTop: theme('spacing.px-80'),
              fontWeight: 600,
            },
            h4: {
              fontSize: theme('fontSize.px-20'),
              letterSpacing: theme('letterSpacing.0'),
              lineHeight: theme('lineHeight.px-30'),
              marginTop: theme('spacing.px-80'),
              fontWeight: 600,
            },
            'ul > li::before': {
              backgroundColor: theme('colors.accentroGray.full'),
            },
            p: {
              '> a': {
                textDecoration: 'none',
                color: theme('colors.accentroAqua.full'),
                '&:hover': {
                  textDecoration: 'underline',
                },
              },
              fontSize: theme('fontSize.px-20'),
              letterSpacing: theme('letterSpacing.0'),
              lineHeight: theme('lineHeight.px-30'),
              marginTop: theme('spacing.px-32'),
              wordWrap: 'break-word',
            },
            blockquote: {
              paddingLeft: 0,
              borderLeft: 'none',
              '> p': {
                fontSize: theme('fontSize.px-24'),
                lineHeight: theme('lineHeight.px-36'),
              },
              '~ h5': {
                color: theme('colors.accentroAqua.full'),
                fontWeight: 600,
                fontSize: theme('fontSize.px-28'),
                lineHeight: theme('lineHeight.px-42'),
                margin: 0,
                '~ p': {
                  marginTop: 0,
                },
              },
            },
          },
        },
        'accentro-lg': {
          css: {
            maxWidth: 'none',
            color: 'inherit',
            h1: {
              fontSize: theme('fontSize.px-72'),
              letterSpacing: theme('letterSpacing.0'),
              lineHeight: theme('lineHeight.px-80'),
              marginBottom: theme('spacing.px-64'),
              marginTop: theme('spacing.px-80'),
              fontWeight: 600,
            },
            h2: {
              fontSize: theme('fontSize.px-48'),
              letterSpacing: theme('letterSpacing.0'),
              lineHeight: theme('lineHeight.px-60'),
              marginBottom: theme('spacing.px-32'),
              marginTop: theme('spacing.px-80'),
              fontWeight: 600,
            },
            h3: {
              fontSize: theme('fontSize.px-32'),
              letterSpacing: theme('letterSpacing.0'),
              lineHeight: theme('lineHeight.px-32'),
              marginTop: theme('spacing.px-80'),
              fontWeight: 600,
            },
            h4: {
              fontSize: theme('fontSize.px-32'),
              letterSpacing: theme('letterSpacing.0'),
              lineHeight: theme('lineHeight.px-32'),
              marginTop: theme('spacing.px-80'),
              fontWeight: 600,
            },
            'ul > li::before': {
              backgroundColor: theme('colors.accentroGray.full'),
            },
            p: {
              '> a': {
                textDecoration: 'none',
                color: theme('colors.accentroAqua.full'),
                '&:hover': {
                  textDecoration: 'underline',
                },
              },
              fontSize: theme('fontSize.px-20'),
              letterSpacing: theme('letterSpacing.0'),
              lineHeight: theme('lineHeight.px-30'),
              wordWrap: 'break-word',
            },
            blockquote: {
              paddingLeft: 0,
              borderLeft: 'none',
              '> p': {
                fontSize: theme('fontSize.px-32'),
                lineHeight: theme('lineHeight.px-48'),
              },
              '~ h5': {
                color: theme('colors.accentroAqua.full'),
                fontWeight: 600,
                fontSize: theme('fontSize.px-32'),
                lineHeight: theme('lineHeight.px-44'),
                margin: 0,
                '~ p': {
                  marginTop: 0,
                },
              },
            },
          },
        },
      }),
      spacing: {
        'px-4': '4px',
        'px-6': '6px',
        'px-8': '8px',
        'px-12': '12px',
        'px-16': '16px',
        'px-20': '20px',
        'px-24': '24px',
        'px-32': '32px',
        'px-40': '40px',
        'px-48': '48px',
        'px-64': '64px',
        'px-80': '80px',
        'px-96': '96px',
        'px-128': '128px',
        'px-160': '160px',
        'px-196': '196px',
        'px-274': '274px',
        'px-784': '784px',
      },
      colors: {
        accentroGray: {
          full: '#2b2d36', // #2b2d36
          800: 'rgba(43, 45, 54, 0.8)',
          600: 'rgba(43, 45, 54, 0.6)',
          500: 'rgba(43, 45, 54, 0.5)',
          400: 'rgba(43, 45, 54, 0.4)',
          300: 'rgba(43, 45, 54, 0.3)',
          200: 'rgba(43, 45, 54, 0.2)',
          100: 'rgba(43, 45, 54, 0.1)',
          50: 'rgba(43, 45, 54, 0.05)',
        },
        accentroDarkGray: {
          full: '#1f2027',
        },
        accentroAqua: {
          full: '#508090',
          50: 'rgba(80, 128, 144, 0.05)',
          100: 'rgba(80, 128, 144, 0.1)',
          200: 'rgba(80, 128, 144, 0.2)',
          300: 'rgba(80, 128, 144, 0.3)',
          400: 'rgba(80, 128, 144, 0.4)',
          500: 'rgba(80, 128, 144, 0.5)',
          600: 'rgba(80, 128, 144, 0.6)',
          700: 'rgba(80, 128, 144, 0.7)',
          800: 'rgba(80, 128, 144, 0.8)',
        },
        accentroAquaLight: {
          full: '#d3dfe3',
        },
        accentroAquaVeryLight: {
          full: '#edf1f2',
        },
        accentroSuccess: {
          full: '#73C49F',
        },
        accentroSuccessLight: {
          full: '#D9E9E2',
        },
        accentroYellowLight: {
          full: '#FFFAEB',
        },
        accentroSecondaryBrown: {
          full: '#B45309',
        },

        accentroWhite: {
          full: '#ffffff',
          50: 'rgba(255, 255, 255, 0.05)',
          100: 'rgba(255, 255, 255, 0.1)',
          200: 'rgba(255, 255, 255, 0.2)',
          500: 'rgba(255, 255, 255, 0.5)',
        },
        accentroRed: {
          full: 'rgba(235, 103, 68, 1)', // #eb6744
          100: 'rgba(235, 103, 68, 0.1)',
        },
        accentroError: {
          full: 'rgb(233, 78, 37)', // '#e94e25',
          50: 'rgba(233, 78, 37, 0.05)',
          100: 'rgba(233, 78, 37, 0.1)',
          200: 'rgba(233, 78, 37, 0.2)',
          300: 'rgba(233, 78, 37, 0.3)',
          400: 'rgba(233, 78, 37, 0.4)',
          500: 'rgba(233, 78, 37, 0.5)',
          600: 'rgba(233, 78, 37, 0.6)',
          700: 'rgba(233, 78, 37, 0.7)',
          800: 'rgba(233, 78, 37, 0.8)',
          900: 'rgba(233, 78, 37, 0.9)',
        },
        accentroDarkError: {
          full: 'rgb(168, 56, 27)', // 'accentroError' rgb values * 0.72, just like for 'accentroGray' and 'accentroDarkGray'
        },
        accentroCream: {
          full: 'rgba(251, 249, 247, 1)',
        },
        accentroAquaMidLight: {
          full: '#709BA9',
        },
        accentroLightYellow: {
          full: '#fffbeb',
        },
        accentroLightGreen: {
          full: '#73c49f',
        },
      },
      transitionProperty: {
        height: 'height',
      },
    },
    keyframes: {
      bounceRight: {
        '0%, 100%': { transform: 'translate(0px)' },
        '50%': { transform: 'translate(10px)' },
      },
      bounceLeft: {
        '0%, 100%': { transform: 'translate(0px)' },
        '50%': { transform: 'translate(-10px)' },
      },
      ripple: {
        '0%': { transform: 'scale(0)', opacity: '1' },
        '100%': { transform: 'scale(40)', opacity: '0' },
      },
    },
    animation: {
      bounceRight: 'bounceRight .6s ease-in-out',
      bounceLeft: 'bounceLeft .6s ease-in-out',
      ripple: 'ripple .3s linear',
    },
  },
  plugins: [
    require('@tailwindcss/line-clamp'),
    require('@tailwindcss/typography')({
      modifiers: ['accentro'],
    }),
    require('@tailwindcss/aspect-ratio'),
    plugin(function ({ addUtilities }) {
      const newUtilities = {
        '.horizontal-tb': {
          writingMode: 'horizontal-tb',
        },
        '.vertical-rl': {
          writingMode: 'vertical-rl',
        },
        '.vertical-lr': {
          writingMode: 'vertical-lr',
        },
      }
      addUtilities(newUtilities)
    }),
  ],
}
