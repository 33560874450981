// src/utils/trpc.ts
import { getToken } from '@dreamstack/auth'
import { IS_ACCENTRO_WEB } from '@dreamstack/feature-components/src/config/config'
import type { OperationResultEnvelope, TRPCLink } from '@trpc/client'
import { TRPCClientError, httpBatchLink } from '@trpc/client'
import { createTRPCNext } from '@trpc/next'
import { createServerSideHelpers } from '@trpc/react-query/server'
import type {
  AnyRouter,
  inferRouterInputs,
  inferRouterOutputs,
} from '@trpc/server'
import { observable } from '@trpc/server/observable'
import type { NextPageContext } from 'next'
import superjson from 'superjson'
import type { AppRouter } from '../server/router/app.router'

const getBaseUrl = () => {
  if (typeof window !== 'undefined') return '' // browser should use relative url
  if (process.env.VERCEL_URL) return `https://${process.env.VERCEL_URL}` // SSR should use vercel url
  return `http://localhost:${process.env.PORT ?? 3000}` // dev SSR should use localhost
}

/** A set of type-safe react-query hooks for your tRPC API. */
export const trpc = createTRPCNext<AppRouter>({
  config: (info: { ctx?: NextPageContext | undefined }) => {
    return {
      transformer: superjson,
      links: [
        typeof window === 'undefined'
          ? serverSideLink({
              routerPromise: import('../server/router/app.router').then(
                (m) => m.appRouter
              ),
              // TODO: Felix, does this really work?
              isPreview: (info.ctx as any)?.HACKY_IS_PREVIEW,
            })
          : httpBatchLink({
              url: `${getBaseUrl()}/api/trpc`,
              headers: async () => {
                const token = IS_ACCENTRO_WEB ? await getToken() : null
                return token ? { Authorization: `Bearer ${token}` } : {}
              },
            }),
      ],
    }
  },
  ssr: true,
})

function serverSideLink<TRouter extends AnyRouter>({
  routerPromise,
  isPreview,
}: {
  routerPromise: Promise<TRouter>
  isPreview: boolean
}): TRPCLink<TRouter> {
  return () =>
    ({ op }) =>
      observable<OperationResultEnvelope<unknown>, TRPCClientError<TRouter>>(
        (observer) => {
          async function execute() {
            const createContext = (await import('../server/context/context'))
              .createContext
            const router = await routerPromise

            const helpers = createServerSideHelpers({
              router: router,
              ctx: await createContext({
                previewModeSecret: isPreview
                  ? process.env.PREVIEW_MODE_SECRET
                  : undefined,
              }),
              transformer: superjson,
            }) as any
            try {
              await helpers[op.path].prefetch(op.input)
              const data = await helpers[op.path].fetch(op.input)
              observer.next({ result: { data, type: 'data' } })
              observer.complete()
            } catch (err) {
              observer.error(TRPCClientError.from(err as Error))
            }
          }
          void execute()
        }
      )
}

/**
 * Inference helper for inputs.
 *
 * @example type HelloInput = RouterInputs['example']['hello']
 */
export type RouterInputs = inferRouterInputs<AppRouter>

/**
 * Inference helper for outputs.
 *
 * @example type HelloOutput = RouterOutputs['example']['hello']
 */
export type RouterOutputs = inferRouterOutputs<AppRouter>
