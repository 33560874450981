import { useLocale, useTranslation } from '@dreamstack/i18n'
import { SimpleTextLink } from '@dreamstack/simple-components'
import Link from 'next/link'
import type { FunctionComponent } from 'react'
import tw, { styled } from 'twin.macro'

const Container = tw.div`
  font-normal text-px-16 ml:(text-px-20 leading-px-30)
`

export const StyledLegalLink = styled(SimpleTextLink)<{
  $isOnDarkBg?: boolean
}>(({ $isOnDarkBg }) => [
  tw`ml:(text-px-20 leading-px-30)`,
  $isOnDarkBg
    ? tw`text-accentroAquaMidLight-full font-semibold`
    : tw` text-accentroAqua-full`,
])

export const LegalImprintText: FunctionComponent<
  React.PropsWithChildren<{
    isOnDarkBg?: boolean
    legalFirm?: string
  }>
> = ({ isOnDarkBg = false, legalFirm = 'ACCENTRO GmbH' }) => {
  const t = useTranslation()
  const locale = useLocale()

  const privacyBaseUrls = {
    de: '/datenschutz',
    en: '/privacy-statement',
  }
  const privacyBaseUrl = privacyBaseUrls[locale]

  const [
    textBeforeLinkBeforeFirm,
    textBeforeLinkAfterFirm,
    textLink,
    textAfterLink,
  ] = t('accentro:legalImprintText', {
    returnObjects: true,
  }) as unknown as string[]
  return (
    <Container>
      {textBeforeLinkBeforeFirm}
      {legalFirm}
      {textBeforeLinkAfterFirm}
      <Link href={privacyBaseUrl} passHref legacyBehavior>
        <StyledLegalLink target="_blank" $isOnDarkBg={isOnDarkBg}>
          {textLink}
        </StyledLegalLink>
      </Link>
      {textAfterLink}
    </Container>
  )
}
