import Cookies from 'js-cookie'
import { v4 as uuidv4 } from 'uuid'

export const getOrCreateAnonymousId = () => {
  const anonymousUserId = Cookies.get('_cioanonid')
  if (anonymousUserId) {
    return anonymousUserId
  }
  const newAnonymousUserId = uuidv4()
  Cookies.set('_cioanonid', newAnonymousUserId)
  return newAnonymousUserId
}
