import tw, { styled } from 'twin.macro'

export const Css_Text_32_48 = tw`text-px-20 leading-px-30 ml:(text-px-24 leading-px-36)`
export const Css_Text_24_36 = tw`text-px-24 leading-px-36`
export const Css_Text_20_30 = tw`text-px-20 leading-px-30`
export const Css_SpeechBubbleText = tw`text-px-20 leading-px-30 md:(text-px-32 leading-px-48)`
export const Css_Text_16_24 = tw`text-px-16 leading-px-24`
export const Css_Text_13_18 = tw`text-px-13 leading-px-18`
export const Css_Text_48_60 = tw`text-px-48 leading-px-60`
export const Css_Text_40_50 = tw`text-px-40 leading-px-50`
export const Css_H1Hero = tw`text-px-40 leading-px-50 md:(text-px-56 leading-px-70) ml:(text-px-72 leading-px-80) font-semibold`
export const Css_HeadingH1 = tw`text-px-28 leading-px-42 md:(text-px-40 leading-px-50) ml:(text-px-56 leading-px-70) font-semibold`
export const Css_HeadingH2 = tw`text-px-24 leading-px-36 md:(text-px-28 leading-px-42) ml:(text-px-40 leading-px-50) font-semibold`
export const Css_HeadingH3 = tw`text-px-20 leading-px-30 md:(text-px-24 leading-px-36) ml:(text-px-28 leading-px-42) font-semibold`
export const Css_PropertyHeadingH3 = tw`text-px-20 leading-px-30 md:(text-px-28 leading-px-42) font-semibold`
export const Css_PropertyPriceText = tw`text-px-24 leading-px-36 md:(text-px-28 leading-px-42) font-semibold`
export const Css_PropertyRoomAndSqmText = tw`text-px-16 leading-px-24 md:(text-px-28 leading-px-42) font-semibold`
export const Css_PropertySmallText = tw`text-px-13 leading-px-18 md:(text-px-16 leading-px-24)`

export const Css_HeadingH4 = tw`text-px-16 leading-px-24 md:(text-px-20 leading-px-30) ml:(text-px-20 leading-px-30) font-semibold`
export const Css_BodyText = tw`text-px-16 leading-px-24 ml:(text-px-20 leading-px-30)`
export const Css_SmallText = tw`text-px-13 leading-px-18 ml:(text-px-16 leading-px-24)`
export const Css_ImageHeadingPropProject = tw`text-px-40 leading-px-50 ml:(text-px-72 leading-px-80) font-semibold`

export const Css_BarChartText = tw`text-px-16 leading-px-18 ml:(text-px-20 leading-px-42)`
export const Css_BarChartLabel = tw`text-px-16 leading-px-18 ml:(text-px-20 leading-px-42)`

export const Css_Text_20_30_To_16_24 = tw`text-px-16 leading-px-24 ml:(text-px-20 leading-px-30)`
export const Css_Text_28_36_To_16_24 = tw`text-px-16 leading-px-24 ml:(text-px-28 leading-px-36)`
export const Css_Text_16_24_To_13_18_5 = tw`text-px-13 leading-px-18 ml:(text-px-16 leading-px-24)`
export const Css_Text_32_44_To_16_24 = tw`text-px-16 leading-px-24 ml:(text-px-32 leading-px-44)`

export const Css_Custom_Block_Map_Font_H1 = tw`text-px-28 leading-px-42 md:(text-px-48 leading-px-60) ml:(text-px-72 leading-px-80) font-semibold`

export const Custom_Block_Map_Font_H1 = styled.h1(() => [
  Css_Custom_Block_Map_Font_H1,
])

export const BarChartText = styled.p(() => [Css_BarChartText])
export const BarChartLabel = styled.p(() => [Css_BarChartLabel])

export const Text_48_60 = styled.p(() => [Css_Text_48_60])
export const Text_40_50 = styled.p(() => [Css_Text_40_50])
export const Text_32_48 = styled.p(() => [Css_Text_32_48])
export const Text_24_36 = styled.p(() => [Css_Text_24_36])
export const Text_20_30 = styled.p(() => [Css_Text_20_30])
export const SpeechBubbleText = styled.p(() => [Css_SpeechBubbleText])
export const Text_16_24 = styled.p(() => [Css_Text_16_24])
export const Text_13_18 = styled.p(() => [Css_Text_13_18])

export const HeadingH1 = styled.h1(() => [Css_HeadingH1])
export const HeadingH2 = styled.h2(() => [Css_HeadingH2])
export const HeadingH4 = styled.h4(() => [Css_HeadingH4, tw`font-semibold`])
export const HeadingH3 = styled.h3(() => [Css_HeadingH3])
export const PropertyHeadingH3 = styled.h3(() => [Css_PropertyHeadingH3])
export const PropertyPriceText = styled.p(() => [Css_PropertyPriceText])
export const PropertyRoomAndSqmText = styled.p(() => [
  Css_PropertyRoomAndSqmText,
])
export const PropertySmallText = styled.p(() => [Css_PropertySmallText])
export const H1Hero = styled.h1(() => [Css_H1Hero])
export const ImageHeadingPropProject = styled.h1(() => [
  Css_ImageHeadingPropProject,
])

export const MoreFlatsSmallText = styled.span(() => [Css_SmallText])

export const BodyText = styled.p(() => [Css_BodyText])

export const FooterHeading = styled.p(() => [Css_Text_20_30, tw`font-semibold`])
