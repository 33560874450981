import { useCallback } from 'react'
import { useGaTrackFormSubmission } from '../googleAnalytics/useGaTrackEvent'

export const useTrackFormSubmissionGA4 = () => {
  const gaTracking = useGaTrackFormSubmission()
  return useCallback(
    ({ formId, formData }: { formId: string; formData: any }) => {
      gaTracking({ formId, formData })
    },
    [gaTracking]
  )
}
