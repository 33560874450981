import { CitiesContactFooter } from '@dreamstack/accentro-contentful'
import { PreviewSecretContext } from '@dreamstack/artemis'
import { AuthModalContextProvider } from '@dreamstack/auth'
import type { IMainLayoutContext } from '@dreamstack/feature-components'
import {
  AuthModal,
  MainLayoutContextProvider,
  Newsletter,
  NotificationContextProvider,
  NotificationHub,
  NotificationPopupProvider,
} from '@dreamstack/feature-components'
import { SeoDefaults } from '@dreamstack/seo'
import { GlobalStyles, ThemeProvider } from '@dreamstack/theme'
import { GoogleAnalyticsContextProvider } from '@dreamstack/tracking'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { SessionProvider as AuthProvider } from 'next-auth/react'
import type { FunctionComponent } from 'react'
import { NEXT_PUBLIC_GOOGLE_ANALYTICS_ID } from '../../config/env'
import { ApolloProvider } from './ApolloProvider'
import { MainSideEffects } from './MainSideEffects'
import { Translator } from './Translator'

export const MainProvider: FunctionComponent<
  React.PropsWithChildren<{ pageProps: any }>
> = ({ children, pageProps }) => {
  // useTranslationWarning()

  // TODO: not for reservation etc?
  const mainLayoutContext: IMainLayoutContext = {
    newsLetterFooter: <Newsletter />,
    contactFooter: <CitiesContactFooter />,
  }

  return (
    <>
      <ThemeProvider>
        <GlobalStyles />
        <PreviewSecretContext.Provider
          value={{ previewModeSecret: pageProps.previewModeSecret }}
        >
          <AuthProvider session={pageProps.session}>
            {/* Translator has to be inside AuthProvider! If they are the other way round, auth breaks when changing language. */}
            <Translator pageProps={pageProps}>
              <SeoDefaults siteTitle="Accentro" />
              <ApolloProvider pageProps={pageProps}>
                <AuthModalContextProvider>
                  <NotificationContextProvider>
                    <NotificationPopupProvider>
                      <ReactQueryDevtools initialIsOpen={false} />
                      <AuthModal />
                      <NotificationHub />
                      <GoogleAnalyticsContextProvider
                        trackingId={NEXT_PUBLIC_GOOGLE_ANALYTICS_ID}
                      >
                        <MainLayoutContextProvider context={mainLayoutContext}>
                          <MainSideEffects></MainSideEffects>
                          <>{children}</>
                        </MainLayoutContextProvider>
                      </GoogleAnalyticsContextProvider>
                    </NotificationPopupProvider>
                  </NotificationContextProvider>
                </AuthModalContextProvider>
              </ApolloProvider>
            </Translator>
          </AuthProvider>
        </PreviewSecretContext.Provider>
      </ThemeProvider>
    </>
  )
}
