import { IS_ACCENTRO_WEB } from '@dreamstack/feature-components'
import { useSession } from 'next-auth/react'
import { useMemo } from 'react'
import type { EnhancedSession } from './auth.types'

export const useSessionTyped = () => {
  if (!IS_ACCENTRO_WEB) return null
  // Conditionally rendered hook is not an issue here, because it will not change ever for the specific deployments.
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data: session } = useSession()
  return session as EnhancedSession | null | undefined
}

export const useSessionTypedWithLoading = () => {
  if (!IS_ACCENTRO_WEB) return null
  // Conditionally rendered hook is not an issue here, because it will not change ever for the specific deployments.
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data: session, status } = useSession()
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const sessionWithStatus = useMemo(() => {
    return { ...session, status }
  }, [session, status])

  return sessionWithStatus as EnhancedSession | null | undefined
}
