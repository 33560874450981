import last from 'lodash/last'
import split from 'lodash/split'
import { useTranslation as useTranslationRaw } from 'next-i18next'
import { useCallback } from 'react'

export const useTranslation = () => {
  const { t } = useTranslationRaw()

  const translate = useCallback(
    (key: string, ...options: any) => {
      const value = t(key, ...options)
      const keyWithoutNamespace = last(split(key, ':'))
      const translationNotFound = value === keyWithoutNamespace
      if (translationNotFound) {
        throw new Error(`Translation not found for "${key}"`)
      }
      return value
    },
    [t]
  )
  return translate
}
