import { signInWithEmail, useAuthModalContext } from '@dreamstack/auth'
import { useTranslation } from '@dreamstack/i18n'
import { SimpleFieldError } from '@dreamstack/simple-components'
import type { FunctionComponent} from 'react';
import { useCallback, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import 'twin.macro'
import tw from 'twin.macro'
import { SimpleMuiButton } from '../../layout/SimpleMuiButton'
import { useAuthYupSchema } from '../validation/yupSchema'
import { AuthEmailDisplay } from './AuthEmailDisplay'
import type { LoginFormData } from './Login'

const StyledForm = tw.form`space-y-px-40 ml:space-y-px-48`

export const ResetPassword: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  const t = useTranslation()
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)

  const { setActiveStep, email } = useAuthModalContext()

  const yupSchemaResolver = useAuthYupSchema(['email'])
  const onSubmit = useCallback(async () => {
    setLoading(true)
    const signInResponse = await signInWithEmail({
      email,
    })
    const success = !!signInResponse?.ok
    setLoading(false)
    success
      ? setActiveStep('ConfirmationEmailSent')
      : setErrorMessage(t('accentro:auth.login.error'))
  }, [email, setActiveStep, t])
  const methods = useForm<LoginFormData>()
  const { handleSubmit } = methods
  return (
    <FormProvider {...methods}>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <AuthEmailDisplay />
        {!!errorMessage && <SimpleFieldError>{errorMessage}</SimpleFieldError>}
        <SimpleMuiButton
          variant="contained"
          type="submit"
          color="primary"
          tw="w-auto"
          loading={loading}
          disabled={loading}
        >
          {t('accentro:auth.requestLoginLink')}
        </SimpleMuiButton>
      </StyledForm>
    </FormProvider>
  )
}
