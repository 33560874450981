import type { TextFieldProps } from '@material-ui/core'
import { TextField, withStyles } from '@material-ui/core'
import merge from 'lodash/merge'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import 'twin.macro'
import TailwindConfig from '../../../../tailwind.config'
import { LayoutProps } from './MuiInputLayoutProps'
import { SimpleFieldError } from './SimpleFieldError'

const TextFieldStyledOnWhite = withStyles((theme) => ({
  root: {
    ...merge(LayoutProps(theme), {
      '& .MuiFilledInput-root': {
        backgroundColor: TailwindConfig.theme.extend.colors.accentroGray['50'],
        '&.Mui-error': {
          borderColor: TailwindConfig.theme.extend.colors.accentroError['full'],
        },
      },
      '& .MuiFilledInput-input': {
        color: TailwindConfig.theme.extend.colors.accentroGray['full'],
      },
      '& .MuiInputLabel-filled': {
        color: TailwindConfig.theme.extend.colors.accentroGray['500'],
      },
    }),
  },
}))(TextField)

const TextFieldStyledOnDark = withStyles((theme) => ({
  root: {
    ...merge(LayoutProps(theme), {
      '& .MuiFilledInput-root': {
        backgroundColor: '#4b4e5c',
        '&.Mui-error': {
          borderColor: TailwindConfig.theme.extend.colors.accentroError['full'],
        },
      },
      '& .MuiFilledInput-input': {
        color: TailwindConfig.theme.extend.colors.accentroWhite['full'],
      },
      '& .MuiInputLabel-filled': {
        color: TailwindConfig.theme.extend.colors.accentroWhite['full'],
      },
      '& .MuiInputLabel-shrink': {
        color: TailwindConfig.theme.extend.colors.accentroWhite['500'],
      },
    }),
  },
}))(TextField)

const OutlinedTextFieldWithoutBorder = withStyles((theme) => ({
  root: {
    ...merge({
      '& .MuiOutlinedInput-root': {
        '& > fieldset': {
          borderWidth: 0,
        },
        '&.Mui-focused fieldset': {
          borderColor: TailwindConfig.theme.extend.colors.accentroAqua['full'],
        },
      },
      '& label': {
        width: '100%',
      },
    }),
  },
}))(TextField)

export const MyComponent = () => {
  return <SimpleInput />
}

// eslint-disable-next-line react/display-name
export const SimpleInput = React.forwardRef<
  HTMLInputElement,
  TextFieldProps & { errorMessage?: string; isOnDarkBg?: boolean }
>((props, ref) => {
  const { errorMessage, isOnDarkBg = false, ...rest } = props
  const formContext = useFormContext()
  const error = props.name
    ? formContext.getFieldState(props.name, formContext.formState).error
        ?.message ?? errorMessage
    : errorMessage

  if (props.variant === 'outlined') {
    return (
      <div tw="w-full">
        <OutlinedTextFieldWithoutBorder
          inputRef={ref}
          color={'secondary'}
          error={!!error}
          variant="outlined"
          {...rest}
        />
        {error && <SimpleFieldError>{error}</SimpleFieldError>}
      </div>
    )
  }
  return (
    <div tw="w-full">
      {isOnDarkBg ? (
        <TextFieldStyledOnDark
          inputRef={ref}
          color={'secondary'}
          error={!!error}
          InputProps={{ disableUnderline: true }}
          {...rest}
          variant={props.variant ?? 'filled'}
        />
      ) : (
        <TextFieldStyledOnWhite
          inputRef={ref}
          color={'secondary'}
          error={!!error}
          InputProps={{ disableUnderline: true }}
          {...rest}
          variant={props.variant ?? 'filled'}
        />
      )}
      {error && <SimpleFieldError>{error}</SimpleFieldError>}
    </div>
  )
})
