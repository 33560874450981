import { Css_Text_16_24_To_13_18_5 } from '@dreamstack/feature-components'
import type { FunctionComponent } from 'react'
import tw, { styled } from 'twin.macro'

const StyledFieldError = styled.div(() => [
  Css_Text_16_24_To_13_18_5,
  tw`text-accentroError-full font-semibold p-px-16`,
])

export const SimpleFieldError: FunctionComponent<React.PropsWithChildren<unknown>> = ({ children }) => {
  return <StyledFieldError>{children}</StyledFieldError>
}
