import { useRouter } from 'next/router'
import type { LocaleString } from '../lang/allLocales'
import { defaultLocale } from '../lang/allLocales'

export const useLocale = (): LocaleString => {
  const nextRouter = useRouter()
  const locale = nextRouter?.locale
  if (locale) {
    return locale as LocaleString
  } else {
    return defaultLocale
  }
}
