import { onError } from '@apollo/client/link/error'
import { getMainDefinition } from '@apollo/client/utilities'

export const createErrorLink = () =>
  onError((errorResponse) => {
    const {
      response,
      graphQLErrors,
      networkError,
      operation: gqlOperation,
    } = errorResponse

    const { operation } = getMainDefinition(gqlOperation.query) as any
    const { operationName, variables } = gqlOperation

    const errorText = (type: string) =>
      `[${type} Error]: The ${operation} ${operationName} failed.`

    if (graphQLErrors) {
      let variablesStringified = null
      try {
        variablesStringified = JSON.stringify(variables)
      } catch (error) {
        //*shrug*
        variablesStringified = `no variables or not stringifyable`
      }
      console.warn(
        errorText('GraphQL'),
        { variables, graphQLErrors },
        { variablesStringified }
      )
    }

    if (networkError) {
      console.warn(errorText('Network'), { variables, networkError })
    }

    const responseErrors = response?.errors
    if (responseErrors) {
      console.warn(errorText('Response'), {
        variables,
        responseErrors: JSON.stringify(responseErrors, null, 2),
      })
    }

    if (!graphQLErrors && !networkError && !responseErrors) {
      console.warn(errorText('UNKNOWN'), errorResponse)
    }
  })
