import { useUserIdWithLoading } from '@dreamstack/auth'
import { useLocale, useTranslation } from '@dreamstack/i18n'
import { SimpleInput } from '@dreamstack/simple-components'
import { useTrackFormSubmissionGA4 } from '@dreamstack/tracking'
import { getOrCreateAnonymousId } from '@dreamstack/tracking/src/getOrCreateAnonymousId'
import { trpc } from '@dreamstack/trpc/client'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import 'twin.macro'
import { useNewsletterAndMagazineYupSchema } from '../auth/validation/yupSchema'
import { useSuccessDialog } from '../confirmation/useSuccessDialog'
import { NewsletterType, SimpleMuiButton } from '../index'
import { LegalImprintCheckbox } from './formElements/LegalImprintCheckbox'

const newsletterInputClassName = 'newsletter-email-input' as const

type NewsletterFormData = {
  title: string
  foreame: string
  surname: string
  agreeToLegalImprint: boolean
  lang: 'de' | 'en'
  email: string
}

export const useNewsletterForm = (mode?: 'light' | 'dark') => {
  const t = useTranslation()
  const { mutateAsync: sendNewsletterRegistration } =
    trpc.newsletter.sendRegisterNewsletter.useMutation()
  const { userId } = useUserIdWithLoading()

  const yupSchemaResolver = useNewsletterAndMagazineYupSchema([
    'agreeToLegalImprint',
    'email',
  ])
  const trackingFormSubmissionForGA4 = useTrackFormSubmissionGA4()
  const { mutateAsync: trackFormSubmissionServer } =
    trpc.anonymousTracking.trackEvent.useMutation()

  const methods = useForm({ resolver: yupSchemaResolver })
  const locale = useLocale()
  const { SuccessDialog, show } = useSuccessDialog({
    title: t('accentro:newsletter'),
    text: t('accentro:newsletterSuccessfullyOrdered'),
  })
  const { handleSubmit, control, reset } = methods
  const onSubmit = (data: NewsletterFormData) => {
    const anonymousUserId = getOrCreateAnonymousId()

    trackingFormSubmissionForGA4({
      formId: 'newsletter',
      formData: {
        email: data.email,
      },
    })

    trackFormSubmissionServer({
      eventName: 'formSubmission',
      userId,
      anonymousUserId,
      data: {
        formId: 'Registered for Newsletter',
        formData: {
          email: data.email,
        },
      },
    })
    if (data.email) {
      sendNewsletterRegistration({
        email: data.email,
        type: NewsletterType.Newsletter,
        locale,
        wantsNewsletter: true,
      })
    }
    show()
    reset()
  }
  const isOnDarkBg = !mode || mode === 'dark'

  const EmailField = (
    <Controller
      defaultValue=""
      name="email"
      control={control}
      render={({ field }) => (
        <SimpleInput
          {...field}
          label={t('accentro:e_mail')}
          tw="w-full"
          isOnDarkBg={isOnDarkBg}
          inputProps={{
            className: newsletterInputClassName,
          }}
        />
      )}
    />
  )
  const LegalImprintField = <LegalImprintCheckbox isOnDarkBg={isOnDarkBg} />
  const SubmitButton = (
    <SimpleMuiButton variant="contained" type="submit" color="primary">
      {t('accentro:subscribeToNewsletter')}
    </SimpleMuiButton>
  )
  return {
    FormProvider: FormProvider,
    formProviderProps: { ...methods },
    formProps: { onSubmit: handleSubmit(onSubmit) },
    SuccessDialog: SuccessDialog,
    EmailField,
    LegalImprintField,
    SubmitButton,
    FormFields: (
      <div tw="space-y-px-32">
        {EmailField}
        {LegalImprintField}
        {SubmitButton}
      </div>
    ),
  }
}
