import type { AuthStep } from '@dreamstack/auth'
import { Css_HeadingH2, Text_20_30 } from '@dreamstack/feature-components'
import { AuthBackButton } from '@dreamstack/feature-components/src/auth/modal/AuthBackButton'
import { Dialog } from '@headlessui/react'
import type { FunctionComponent } from 'react'
import tw, { styled } from 'twin.macro'
import { ModalCloseButton } from './ModalCloseButton'

const DialogContent = tw.div`mt-px-16 w-full`
const DialogTitle = styled.h2(() => [Css_HeadingH2, tw`font-semibold`])
const ModalWrapper = styled.div(() => [
  tw`max-height[90vh] overflow-y-auto p-px-24 pt-px-40 mx-px-24 sm:(mx-auto p-px-40 pt-px-64) md:(w-[540px] max-w-[540px]) ml:(p-px-64 pt-px-80 w-[680px] max-w-[680px]) 
  relative bg-accentroWhite-full max-w-[400px] w-full`,
])
const IntroWrapper = styled.div(() => [tw`mb-px-40 ml:(mb-px-48)`])

export type ModalProps = {
  open?: boolean
  title: string
  handleClose: CallableFunction
  goBackTo?: AuthStep
  intro?: string
}

export const SimpleModal: FunctionComponent<React.PropsWithChildren<ModalProps>> = ({
  open,
  handleClose,
  title,
  goBackTo,
  children,
  intro,
}) => {
  return open ? (
    <Dialog
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="form-dialog-title"
      tw="fixed z-20 inset-0 overflow-y-auto flex items-center justify-center"
    >
      <Dialog.Overlay tw="fixed inset-0 backdrop-blur-sm bg-accentroGray-300" />
      <ModalWrapper>
        <ModalCloseButton handleClose={handleClose} />
        <IntroWrapper>
          <div tw="flex  mb-px-16 items-center ">
            {goBackTo && <AuthBackButton toStep={goBackTo} />}
            <DialogTitle>{title}</DialogTitle>
          </div>
          {!!intro && <Text_20_30>{intro}</Text_20_30>}
        </IntroWrapper>
        <DialogContent>{children}</DialogContent>
      </ModalWrapper>
    </Dialog>
  ) : null
}
