import { SimpleMuiButton } from '@dreamstack/feature-components'
import { useTranslation } from '@dreamstack/i18n'
import { Download, Pdf } from '@dreamstack/icons'
import type { FullAssetFragment } from '@dreamstack/investors-graphql'
import Image from 'next/legacy/image'
import type { FunctionComponent } from 'react'

export const EmbeddedAsset: FunctionComponent<
  React.PropsWithChildren<{
    asset: FullAssetFragment
  }>
> = ({ asset }) => {
  const t = useTranslation()
  const { title, url, height, width, description, contentType } = asset
  const containsPdf = contentType?.match('pdf')
  const containsImg = contentType?.match('image')

  if (containsPdf) {
    return (
      <a href={url ?? '#'} target="_blank" rel="noreferrer">
        <SimpleMuiButton
          variant={'text'}
          color={'secondary'}
          startIcon={<Pdf tw="text-accentroGray-full" />}
        >
          {t('accentro:embeddedAsset.PDF')}
        </SimpleMuiButton>
      </a>
    )
  } else if (containsImg)
    return (
      <div>
        <Image
          src={url ?? ''}
          alt={description ?? 'no description'}
          height={height ?? 0}
          width={width ?? 0}
          title={title ?? ''}
        />
      </div>
    )
  else {
    return (
      <a href={url ?? '#'} target="_blank" rel="noreferrer">
        <SimpleMuiButton
          variant={'text'}
          color={'secondary'}
          startIcon={<Download tw="text-accentroGray-full" />}
        >
          {t('accentro:embeddedAsset.documentDownload')}
        </SimpleMuiButton>
      </a>
    )
  }
}
