import { Clear } from '@dreamstack/icons'
import 'twin.macro'

type Props = {
  handleClose: CallableFunction
}

export const ModalCloseButton = (props: Props) => {
  return (
    <button
      tw="absolute top-px-16 right-px-16 md:(top-px-32 right-px-32) cursor-pointer"
      data-cy="modal_close_button"
      onClick={() => props.handleClose()}
    >
      <Clear tw="h-px-32 w-px-32" />
    </button>
  )
}
