import type { FullHyperlinkFragment } from '@dreamstack/investors-graphql'
import Link from 'next/link'
import type { FunctionComponent } from 'react'

export const EmbeddedAssetHyperlink: FunctionComponent<React.PropsWithChildren<{
  hyperlinkAsset: FullHyperlinkFragment
}>> = ({ hyperlinkAsset, children }) => {
  return (
    <Link
      href={hyperlinkAsset.url ?? '#'}
      passHref
      target="_blank"
      rel="nofollow noreferrer"
    >
      {children}
    </Link>
  )
}
