/* eslint-disable react/display-name */
import { useLocale, useTranslation } from '@dreamstack/i18n'
import {
  SocialMediaFacebook,
  SocialMediaInstagram,
  SocialMediaLinkedin,
  SocialMediaXing,
  SocialMediaYoutube,
} from '@dreamstack/icons'
import { trpc } from '@dreamstack/trpc/client'
import { formatPhoneForHref } from '@dreamstack/util'
import map from 'lodash/map'
import Link from 'next/link'
import type { FunctionComponent } from 'react'
import 'twin.macro'
import { FooterHeading, Text_16_24 } from '../layout/Texts'

const socials = [
  {
    Icon: () => <SocialMediaFacebook tw="hocus:text-accentroAqua-full" />,
    className: 'social-facebook',
    href: 'https://www.facebook.com/Accentro-Immobilien-188970351240067/',
  },
  {
    Icon: () => <SocialMediaInstagram tw="hocus:text-accentroAqua-full" />,
    className: 'social-instagram',
    href: 'https://www.instagram.com/accentro.realestate/',
  },
  {
    Icon: () => <SocialMediaYoutube tw="hocus:text-accentroAqua-full" />,
    className: 'social-youtube',
    href: 'https://www.youtube.com/channel/UCQQCpohq8Q2m9mMJae2e-KA',
  },
  {
    Icon: () => <SocialMediaXing tw="hocus:text-accentroAqua-full" />,
    className: 'social-xing',
    href: 'https://www.xing.com/companies/accentrogmbh',
  },
  {
    Icon: () => <SocialMediaLinkedin tw="hocus:text-accentroAqua-full" />,
    className: 'social-linkedin',
    href: 'https://www.linkedin.com/company/accentro',
  },
  // TODO: Get icon and enable this
  // {
  //   Icon: PINTEREST,
  //   className: 'social-pinterest',
  //   href: 'https://www.pinterest.de/accentro/',
  // },
]

export const ContactFooter: FunctionComponent<
  React.PropsWithChildren<{}>
> = () => {
  const t = useTranslation()
  const locale = useLocale()
    const { data: footer } = trpc.cms.getFooter.useQuery({ locale }, {
        trpc: { ssr: true },
        staleTime: Infinity,
    })
  const formattedPhone = footer?.phone
    ? formatPhoneForHref({ digits: footer.phone })
    : undefined
  const formattedFax = footer?.fax
    ? formatPhoneForHref({ digits: footer.fax })
    : undefined

  return (
    <div>
      <FooterHeading tw="mb-px-24">
        {t('accentro:footer.contactHeading')}
      </FooterHeading>
      <div tw="flex flex-col mb-px-32 space-y-px-24 md:(flex-row space-x-px-80 space-y-0)">
        <div tw="space-y-px-24 md:space-y-px-16">
          <div>
            <Text_16_24 tw="font-semibold">
              {t('accentro:footer.telefon')}
            </Text_16_24>
            <Text_16_24>
              <a href={formattedPhone}>{footer?.phone}</a>
            </Text_16_24>
          </div>
          <div>
            <Text_16_24 tw="font-semibold">
              {t('accentro:footer.fax')}
            </Text_16_24>
            <Text_16_24>
              <a href={formattedFax}>{footer?.fax}</a>
            </Text_16_24>
          </div>
          <div>
            <Text_16_24 tw="font-semibold">E-Mail</Text_16_24>
            <Text_16_24 tw="text-accentroAquaMidLight-full font-semibold">
              <a href="mailto:mail@accentro.de">mail@accentro.de</a>
            </Text_16_24>
          </div>
        </div>
        <div>
          <Text_16_24 tw="font-semibold">
            {t('accentro:footer.address')}
          </Text_16_24>
          <Text_16_24>ACCENTRO Berlin</Text_16_24>
          <Text_16_24>Kantstraße 44/45</Text_16_24>
          <Text_16_24>10625 Berlin</Text_16_24>
        </div>
      </div>
      <div tw="flex space-x-px-12">
        {map(socials, ({ href, className, Icon }) => (
          <Link
            href={href}
            passHref
            key={className}
            target="_blank"
            className={className}
          >
            <Icon />
          </Link>
        ))}
      </div>
    </div>
  )
}
