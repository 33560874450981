import { SimpleInput } from '@dreamstack/simple-components'
import type { Control, FieldValues, Path, PathValue } from 'react-hook-form'
import { Controller } from 'react-hook-form'

export const ControlledPassword = <T extends FieldValues>({
  control,
  onChangeCb,
  errorMessage,
  name,
  label,
  autoFocus,
}: React.PropsWithChildren<{
  control: Control<T>
  onChangeCb?: CallableFunction
  errorMessage?: string
  name: Path<T>
  label: string
  autoFocus?: boolean
}>) => {
  return (
    <Controller
      defaultValue={'' as PathValue<T, Path<T>>}
      control={control}
      name={name}
      render={({ field: { onChange, ...props } }) => {
        return (
          <SimpleInput
            {...props}
            onChange={(e) => {
              onChange(e)
              onChangeCb && onChangeCb(e)
            }}
            autoFocus={autoFocus}
            name={name}
            id={name}
            label={label}
            type="password"
            fullWidth
            autoComplete="current-password"
            error={!!errorMessage}
            errorMessage={errorMessage}
          />
        )
      }}
    />
  )
}
