import type { FunctionComponent} from 'react';
import { createContext, useContext, useState } from 'react'

export type NotificationPopupType = 'AddFavorite'

type NotificationPopup = {
  id: string
  type: NotificationPopupType
}

const useNotificationPopupProviderState = () => {
  const [selectedNotificationPopup, setSelectedNotificationPopup] =
    useState<NotificationPopup>()
  return {
    selectedNotificationPopup,
    setselectedNotificationPopup: setSelectedNotificationPopup,
  }
}

type INotificationPopupProvider = ReturnType<
  typeof useNotificationPopupProviderState
>

export const NotificationPopupContext = createContext(
  {} as INotificationPopupProvider
)

export const NotificationPopupProvider: FunctionComponent<React.PropsWithChildren<unknown>> = ({ children }) => {
  const context = useNotificationPopupProviderState()
  return (
    <NotificationPopupContext.Provider value={context}>
      {children}
    </NotificationPopupContext.Provider>
  )
}

export function useNotificationPopupContext() {
  return useContext(NotificationPopupContext)
}
