import type { AuthStep } from '@dreamstack/auth'
import { useAuthModalContext } from '@dreamstack/auth'
import { useTranslation } from '@dreamstack/i18n'
import { useMemo } from 'react'
import { AccentroTermsConsent } from './AccentroTermsConsent'
import { AccentroTermsConsentWithPassword } from './AccentroTermsConsentWithPassword'
import { ConfirmationEmailSent } from './ConfirmationEmailSent'
import { ConfirmationEmailSentSuccessPage } from './ConfirmationEmailSentSuccessPage'
import { Login } from './Login'
import { LoginOrRegister } from './LoginOrRegister'
import { LoginWithPassword } from './LoginWithPassword'
import { ResetPassword } from './ResetPassword'
export interface ModalComponent {
  component: JSX.Element
  title: string
  intro?: string
  goBackTo?: AuthStep
}

export const useAuthModalActiveComponent = () => {
  const { activeStep } = useAuthModalContext()
  const t = useTranslation()
  const activeComponent = useMemo((): ModalComponent | null => {
    switch (activeStep) {
      case 'loginOrRegister':
        return {
          component: <LoginOrRegister />,
          title: `${t('accentro:login')} / ${t('accentro:registration')}`,
          intro: t('accentro:LoginOrRegisterText'),
        }
      case 'login':
        return {
          component: <Login />,
          title: t('accentro:login'),
          goBackTo: 'loginOrRegister',
          intro: t('accentro:auth.loginBodyText'),
        }
      case 'loginWithPassword':
        return {
          component: <LoginWithPassword />,
          title: t('accentro:login'),
          goBackTo: 'login',
        }
      case 'resetPassword':
        return {
          component: <ResetPassword />,
          title: t('accentro:resetPassword'),
          goBackTo: 'login',
          intro: t('accentro:resetPasswordText'),
        }
      case 'ConfirmationEmailSent':
        return {
          component: <ConfirmationEmailSent />,
          title: `${t('accentro:login')} / ${t('accentro:registration')}`,
          intro: t('accentro:auth.confirmationEmailSentBody'),
        }
      case 'ConfirmationEmailSentSuccessPage':
        return {
          component: <ConfirmationEmailSentSuccessPage />,
          title: t('accentro:confirmationEmailSentSuccessPageTitle'),
        }
      case 'AccentroTermsConsent':
        return {
          component: <AccentroTermsConsent />,
          title: t('accentro:legalImprintTextTitle'),
        }
      case 'AccentroTermsConsentWithPassword':
        return {
          component: <AccentroTermsConsentWithPassword />,
          title: t('accentro:legalImprintTextTitle'),
        }
      default:
        return null
    }
  }, [activeStep, t])
  return activeComponent
}
