import { getSession as _getSession, signOut } from 'next-auth/react'
import type { EnhancedSession } from './auth.types'

export const getSession = async ({ req }: { req?: any } = {}) => {
  const isSsr = typeof window === 'undefined'
  if (isSsr && !req) return undefined
  const session = (await _getSession({ req })) as
    | EnhancedSession
    | null
    | undefined
  if (session && 'error' in session) {
    signOut({ callbackUrl: `${window.location.origin}` })
  }
  return session
}
