// TODO: consider ../next.config.js
/* eslint-disable @typescript-eslint/no-var-requires */
const path = require('path')
const { supportedLocales } = require('./config/locales')

module.exports = {
  i18n: {
    locales: supportedLocales,
    defaultLocale: 'de',
  },
  localePath: path.resolve('./public/static/locales'),
  react: {
    useSuspense: false,
    // wait: true,
  },
}
