import { useTranslation } from '@dreamstack/i18n'
import { SimpleModal } from '@dreamstack/simple-components'
import type { FunctionComponent } from 'react'
import tw from 'twin.macro'
import { SimpleMuiButton } from '../index'

const StyledFooter = tw.div`flex gap-px-16 flex-row flex-wrap`
const ActionWrapper = tw.div`mb-0`

export const SuccessDialog: FunctionComponent<React.PropsWithChildren<{
  title?: string
  text: string
  open: boolean
  onHandleClose: CallableFunction
  onCtaClick?: CallableFunction
  ctaLabel?: string
}>> = ({ title, text, open, onHandleClose, ctaLabel, onCtaClick }) => {
  const t = useTranslation()
  ctaLabel = ctaLabel ?? t('accentro:continue')
  return (
    <SimpleModal
      open={open}
      handleClose={() => onHandleClose()}
      title={title ?? ''}
      intro={text}
    >
      {ctaLabel && (
        <ActionWrapper>
          <StyledFooter>
            <SimpleMuiButton
              variant="contained"
              color="primary"
              onClick={async () => {
                onCtaClick && onCtaClick()
                onHandleClose()
              }}
            >
              {ctaLabel}
            </SimpleMuiButton>
          </StyledFooter>
        </ActionWrapper>
      )}
    </SimpleModal>
  )
}
