import { signInWithEmail, useAuthModalContext } from '@dreamstack/auth'
import { useTranslation } from '@dreamstack/i18n'
import { trpc } from '@dreamstack/trpc/client'
import type { FunctionComponent } from 'react'
import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import 'twin.macro'
import tw from 'twin.macro'
import { SimpleMuiButton } from '../../layout/SimpleMuiButton'
import { ControlledAuthEmail } from '../forms/ControlledEmail'
import { useAuthYupSchema } from '../validation/yupSchema'
import { AuthButtonArrow } from './AuthButtonArrow'

type LoginStepOptions =
  | 'login'
  | 'AccentroTermsConsent'
  | 'ConfirmationEmailSent'
  | 'AccentroTermsConsentWithPassword'

type LoginOrRegisterFormData = {
  email: string
}

const ContentLayout = tw.div`flex mb-0`
const Col = tw.form`flex-1 space-y-px-40 ml:space-y-px-48`

export const LoginOrRegister: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  const t = useTranslation()
  const [loading, setLoading] = useState(false)

  // These are Mutations instead of querys, because we want to trigger them in the onSubmit function when the Button is clicked
  const { mutateAsync: checkEmailRegistered } = trpc.userPublic.getEmailIsRegistered.useMutation(
)
  const { mutateAsync: userHasPassword } = trpc.userPublic.getHasPassword.useMutation()

  const { mutateAsync: checkContactAcceptedTerms } = trpc.userPublic.propstackUserAcceptedTerms.useMutation()

  const yupSchemaResolver = useAuthYupSchema(['email'])
  const methods = useForm<LoginOrRegisterFormData>({
    resolver: yupSchemaResolver,
  })
  const { handleSubmit, control } = methods

  const { setActiveStep, setEmail } = useAuthModalContext()

  const onSubmit = async (formData: LoginOrRegisterFormData) => {
    setLoading(true)

    // on Button click/Submit call the mutations to check if the user is registered, has a password and has accepted the terms
    const { isRegistered } = await checkEmailRegistered({
      email: formData.email,
    })
    const hasPassword = await userHasPassword({
      email: formData.email,
    })

    const hasAcceptedTerms = await checkContactAcceptedTerms({
      email: formData.email,
    })

    setEmail(formData.email)
    const loginStep: LoginStepOptions = getLoginStep(
      isRegistered,
      hasPassword,
      hasAcceptedTerms
    )
    switch (loginStep) {
      case 'login':
        setActiveStep('login')
        break
      case 'AccentroTermsConsent':
        setActiveStep('AccentroTermsConsent')
        break
      case 'ConfirmationEmailSent':
        await signInWithEmail({ email: formData.email })
        setActiveStep('ConfirmationEmailSent')
        break
      case 'AccentroTermsConsentWithPassword':
        setActiveStep('AccentroTermsConsentWithPassword')
        break
      default:
        setActiveStep('login')
        break
    }

    setLoading(false)
  }
  return (
    <>
      <ContentLayout>
        <FormProvider {...methods}>
          <Col onSubmit={handleSubmit(onSubmit)}>
            <ControlledAuthEmail control={control} />
            <SimpleMuiButton
              type="submit"
              disabled={loading}
              variant="contained"
              endIcon={<AuthButtonArrow />}
              color="primary"
              tw="w-auto"
              loading={loading}
            >
              {t('accentro:continue')}
            </SimpleMuiButton>
          </Col>
        </FormProvider>
      </ContentLayout>
    </>
  )
}

const getLoginStep = (
  isRegistered: boolean,
  hasPassword: boolean,
  hasAcceptedTerms: boolean
) => {
  // Cases if User is already registered -> Need to check if AccentroTermsConsent is needed
  if (isRegistered) {
    //case: user has no password and has not accepted terms -> Show AccentroTermsConsent
    if (!hasPassword && !hasAcceptedTerms) {
      return 'AccentroTermsConsent'
    }
    //case: user has no password but has accepted terms -> Send Magic Link without showing AccentroTermsConsent
    if (!hasPassword && hasAcceptedTerms) {
      return 'ConfirmationEmailSent'
    }
    //case: user has password but has not accepted terms -> Show AccentroTermsConsentWithPassword
    if (!hasAcceptedTerms) {
      return 'AccentroTermsConsentWithPassword'
    } else {
      //case: user has password and has accepted terms -> Show normal login
      return 'login'
    }
  } else {
    // Cases if User is not registered yet -> Always show AccentroTermsConsent
    return 'AccentroTermsConsent'
  }
}
