// FROM: https://www.apollographql.com/docs/react/data/fragments/#defining-possibletypes-manually
export const possibleTypes = {
  Entry: [
    'StaticPage',
    'NavBarEntry',
    'Seo',
    'BlockAccordion',
    'BlockAccordionEntry',
    'BlockCallToActionButton',
    'BlockTwoColumn',
    'BlockOneColumn',
    'BlockThreeColumn',
    'BlockRichText',
    'BlockCustom',
    'BlockPersonAccordion',
    'PersonAccordionEntry',
    'BlockImageGallery',
    'BarChartWithBackground',
    'BlockRibbon',
    'BlockTable',
    'LatestDownload',
    'NavigationEntry',
    'BlockStockPrice',
    'BlockBigTitleNavigation',
    'BlockAnalystRating',
    'BlockFullsizeImage',
    'BlockPartnerGrid',
    'BlockHighlightRealEstate',
    'BlockVideo',
    'BlockLinkSection',
    'BlockSubNavigation',
    'BlockTextCloud',
    'BlockSpeechBubble',
    'BlockSlider',
    'BlockSliderEntry',
    'BlockHiddenWithConsent',
  ],
  // StaticPageBlocksItem: [
  //   'BlockAccordion',
  //   'BlockCallToActionButton',
  //   'BlockRichText',
  //   'BlockTwoColumn',
  // ],
}
