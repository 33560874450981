import { signIn } from 'next-auth/react'
export interface SignInWithEmailOptions {
  email: string
}
export const signInWithEmail = async ({ email }: SignInWithEmailOptions) => {
  return signIn('email', {
    email,
    redirect: false,
  })
}
