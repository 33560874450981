export const PROPSTACK_RESERVATION_LOGIN_GROUP_ID =
  process.env.PROPSTACK_RESERVATION_LOGIN_GROUP_ID ?? 42
export const PROPSTACK_RESERVATION_MUST_BE_UNQIUE =
  Boolean(process.env.PROPSTACK_RESERVATION_MUST_BE_UNQIUE) || true
export const PROPSTACK_RESERVATION_TASK_TITLE =
  process.env.PROPSTACK_RESERVATION_TASK_TITLE ||
  'Eine Immobilie wurde reserviert'
export const PROPSTACK_RESERVATION_DEAL_PIPELINE_ID =
  Number(process.env.PROPSTACK_RESERVATION_DEAL_PIPELINE_ID) || 2
export const PROPSTACK_PRESALES_DEAL_PIPELINE_ID =
  Number(process.env.PROPSTACK_PRESALES_DEAL_PIPELINE_ID) || 3
export const PROPSTACK_RESERVATION_DEAL_BROKER_ID =
  Number(process.env.PROPSTACK_RESERVATION_DEAL_BROKER_ID) || NaN
export const PROPSTACK_RESERVATION_COLLECTIVE_MAILBOX =
  process.env.PROPSTACK_RESERVATION_COLLECTIVE_MAILBOX || 'teamdev@sodefa.de'
export const PROPSTACK_API_URL = process.env.PROPSTACK_API_URL
export const PROPSTACK_API_KEY = process.env.PROPSTACK_API_KEY!
export const CONTACT_FORM_EMAIL_TO =
  process.env.CONTACT_FORM_EMAIL_TO || 'teamdev@sodefa.de'
export const IS24_CONTACT_FORM_EMAIL_TO =
  process.env.IS24_CONTACT_FORM_EMAIL_TO || 'teamdev@sodefa.de'
export const PROVIDERS_AGENTS_CONTACT_FORM_EMAIL_TO =
  process.env.PROVIDERS_AGENTS_CONTACT_FORM_EMAIL_TO || 'teamdev@sodefa.de'
export const CONTACT_FORM_SUBJECT =
  process.env.CONTACT_FORM_SUBJECT || 'Kontaktanfrage'
export const AUTH_MASTER_PW = process.env.AUTH_MASTER_PW
export const NEXT_PUBLIC_USERCENTRICS_ID =
  process.env.NEXT_PUBLIC_USERCENTRICS_ID || 'jWtoeXLkA'
export const NEXT_PUBLIC_GOOGLE_ANALYTICS_ID =
  process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID || 'G-M7F04CRGPR'
export const NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID =
  process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID || 'GTM-P3W94JP'
export const NEXT_PUBLIC_TIKTOK_PIXEL_ID = process.env.NEXT_PUBLIC_TIKTOK_PIXEL_ID || 'CSUR0CBC77U9A53N24U0'

// TODO: re-enable to a reasonable number once case is handled and user can reset
export const PASSWORD_MAX_ACCESS_FAILED_COUNT = Infinity
export const DOCUSIGN_HMAC_SECRET = process.env.DOCUSIGN_HMAC_SECRET!
